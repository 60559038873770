import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ConnectorCollaboratorService } from '../../../services/Collaborator/conector-collaborator.service';
import { GenericCatalogsService } from '../../../services/GenericCatalogs.service';
import { IntegrityReportService } from '../../../services/integrity-report.service';
import { ConnectorJobProfileService } from '../../../services/job-profile.service';
import EvaluationInformation from '../../../types/db-evaluations-info.type';
import { modalCommentComponent } from './modal-comments/modal-comments.component';
import { modalProfileReportConfig } from './modal-profile-report-config/modal-profile-report-config.component';
import { modalSeleccionReportConfig } from './modal-seleccion-report-config/modal-seleccion-report-config.component';
import { ConnectorGroupService } from '../../../services/Admin/conector-group.service';

@Component({
	selector: 'app-integrity-reports',
	templateUrl: './integrity-reports.component.html',
	styleUrls: ['./integrity-reports.component.css'],
})
export class IntegrityReportsComponent implements OnInit {
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	evaluationList = [];
	jobProfileList = [];
	jobProfileByEvaluationType = [];
	selectedIndex = 0;
	evaluations = [];
	pageSize = 10;
	currentPage = 0;
	totalEvaluationsInformations = 0;
	sortDescending = true;
	tableMessage = 'Por favor, realiza una búsqueda para mostrar información.';
	pageSizeOptions: number[] = [10, 25, 50];
	dataSource = new MatTableDataSource<EvaluationInformation>(null);
	isHonestySelection = false;
	isBGCheck = false;
	typeEvaluationDisableButtonBo = true;
	selectedEvaluation = 0;

	reportForm: FormGroup;
	displayedColumns: string[] = [
		'select',
		'true_Identity',
		'nickname',
		'name',
		'email',
		'jobProfile',
		'endDate',
		'report',
	];
	selection;
	@ViewChild(modalCommentComponent, null)
	csc: modalCommentComponent;
	groupsCategoryCompany: any = [];
	subgroupsOfSelectedGroup: any = [];
	groupSelected: number = 0;
	subgroupSelected: number = 0;
	constructor(
		private conector: GenericCatalogsService,
		private conector_collab: ConnectorCollaboratorService,
		private job: ConnectorJobProfileService,
		private report: IntegrityReportService,
		private form: FormBuilder,
		private dialog: MatDialog,
		private groupService: ConnectorGroupService
	) {
		this.selection = new SelectionModel<EvaluationInformation>(true, []);
	}

	ngOnInit(): void {
		this.reportForm = this.form.group({
			evaluation: [null, Validators.required],
			jobProfile: [0],
			group: [0],
			nickname: [null],
			email: [null],
			name: [null],
			firstname: [null],
			lastname: [null],
			rangeInit: [null],
			rangeFinal: [null],
		});

		this.conector.ct_Submodules(1).subscribe((response: any) => {
			const { data, msg } = response;
			console.log('evaluationList ============================');
			console.log(data);
			this.evaluationList = data.filter((f) => f.id != 10 && f.id != 13);
		});

		this.job.jobprofile_all().subscribe((response: any) => {
			const { ok, data, msg } = response;

			data.push({ id: 0, label: '-Seleccionar puesto-' });
			data.sort(function (a, b) {
				return a.label.localeCompare(b.label);
			});

			this.jobProfileList = data;
		});
		this.groupService.getAllGroups().subscribe((groups) => {
			this.groupsCategoryCompany = [...groups, { id: 0, name: '-Seleccionar grupo-' }];
			this.groupsCategoryCompany.sort((a, b) => a.id - b.id);
		});
	}

	SearchEvalautions(formdata) {
		this.isHonestySelection = formdata.evaluation == 1;
		this.isBGCheck = formdata.evaluation == 12;

		switch (formdata.evaluation) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 10:
				this.displayedColumns = [
					'select',
					'true_Identity',
					'nickname',
					'name',
					'email',
					'jobProfile',
					'resultEvaluation',
					'endDate',
					'report',
				];
				break;
			case 12:
				this.displayedColumns = [
					'select',
					'nickname',
					'name',
					'email',
					'jobProfile',
					'resultEvaluation',
					'endDate',
					'status',
					'serviceStatus',
					'report'
				];
				break;
			default:
				this.displayedColumns = [
					'select',
					'true_Identity',
					'nickname',
					'name',
					'email',
					'jobProfile',
					'endDate',
					'report',
				];
				break;
		}

		//reset pagination
		this.currentPage = 0;
		this.pageSize = 10;
		this.totalEvaluationsInformations = 0;

		this.loadEvaluationsInformations();
	}

	loadEvaluationsInformations() {
		var groupId = this.subgroupSelected === 0 ? this.groupSelected : this.subgroupSelected;
		this.reportForm.get("group").setValue(groupId);
		this.report
			.evaluations_get(this.reportForm.value, this.currentPage, this.pageSize)
			.pipe(catchError((error) => of({ ok: false, msg: '0', data: [] })))
			.subscribe((response) => {
				const { data, msg, ok } = response;

				if (!ok) {
					Swal.fire('Error', 'Ha ocurrido un error al cargar las evaluaciones', 'error');
					return;
				}

				this.evaluations = data;
				this.totalEvaluationsInformations = parseInt(msg);
				this.dataSource = new MatTableDataSource<EvaluationInformation>(data);

				if (this.paginator) {
					this.paginator.length = this.totalEvaluationsInformations;
					this.paginator.pageIndex = this.currentPage;
				}
			});
	}
	getSubgroupsOfSelectedGroup(seletedGroupId: number) {
		this.groupSelected = seletedGroupId;
		this.subgroupSelected = 0;
		this.subgroupsOfSelectedGroup = [];
		if (seletedGroupId != 0) {
			this.groupService
				.getAllSubGroupsByGroupId(seletedGroupId)
				.subscribe((subgroups) => {
					if (subgroups.length > 0) {
						this.subgroupsOfSelectedGroup = [...subgroups, { id: 0, name: '-Seleccionar subgrupo-' }];
						this.subgroupsOfSelectedGroup.sort((a, b) => a.id - b.id);
					}
				});
		}
	}
	getSubgroupSelected(selectedSubgroupId: number) {
		this.subgroupSelected = 0;
		if (selectedSubgroupId != 0) {
			this.subgroupSelected = selectedSubgroupId;
		}
	}

	onPageChange(event: PageEvent) {
		this.pageSize = event.pageSize;
		this.currentPage = event.pageIndex;
		this.loadEvaluationsInformations();
	}

	DownloadReport(row) {
		Swal.fire({
			title: 'Descargando reporte',
			text: 'Espere un momento...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				Swal.showLoading();
			},
		});

		var formdata = {
			idevaluated: row.idUser,
			idjobprofile: row.jobProfileid,
			idsubmodule: this.reportForm.value.evaluation,
			typereport: 1, // 1 pdf, 2 json
		};

		this.report.downloadselection_post(formdata).subscribe((response: any) => {
			const { data, msg, ok } = response;
			Swal.close();

			if (ok) {
				console.log(response.data);

				const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
				const downloadLink = document.createElement('a');
				const fileName = response.data.fileName;

				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			} else {
				Swal.fire('Error', msg, 'error');
			}
		});
	}

	RequestReport(user) {
		this.conector_collab.requestReport(user.idUser).subscribe(async (e: any) => {
			if (e != null && e.ok == true) {
				Swal.fire('Completado', e.data.message, 'success');
			}
			if (e.ok == false) {
				Swal.fire('Advertencia', e.msg, 'warning');
			}
		});
	}

	ResponseReport(user) {
		this.conector_collab.responseReport(user.idUser).subscribe(async (e: any) => {
			if (e.ok == true) {
				Swal.fire('Notificación', e.data.message, 'success');
			}
			if (e.ok == false) {
				Swal.fire('Advertencia', e.msg, 'warning');
			}
		});
	}

	openDialog(element): void {
		console.log('element ======================================');
		console.log(element);

		//this.csc.openModal = true;

		const dialogRef = this.dialog.open(modalCommentComponent, {
			width: '600px',
			data: { evaluation: element.evaluation, comment: element.comments },
		});

		dialogRef.afterClosed().subscribe((result) => {
			console.log('The dialog was closed');
			// this.animal = result;
			const formdata = {
				evaluation: 1,
			};
			this.isHonestySelection = true;
			this.SearchEvalautions(formdata);
		});
	}

	typeEvaluation() {
		switch (this.selectedEvaluation) {
			case 1:
				this.dialog.open(modalSeleccionReportConfig, {
					width: '600px',
				});
				break;
			case 3:
				this.dialog.open(modalProfileReportConfig, {
					width: '600px',
				});
				break;
		}
	}

	typeEvaluationDisableButton(id) {
		this.dataSource = new MatTableDataSource<EvaluationInformation>(null);
		this.selectedEvaluation = id;

		if (this.paginator) {
			this.paginator.length = 0;
			this.paginator.pageIndex = 0;
		}

		this.job.jobprofile_get_evaluation_type(id).subscribe((response: any) => {
			const { data, msg } = response;

			if (id === 1) {
				this.jobProfileByEvaluationType = data;
				this.typeEvaluationDisableButtonBo = false;
			} else if (id === 3) {
				this.jobProfileByEvaluationType = data;
				this.typeEvaluationDisableButtonBo = false;
			} else {
				this.typeEvaluationDisableButtonBo = true;
			}
		});
	}
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		return numSelected > 0;
	}

	toggleAllRows() {
		this.isAllSelected()
			? this.selection.clear()
			: this.dataSource.data.forEach((row) => {
				if (row.status === 4) {
					this.selection.select(row);
				}
			});
	}

	download() {
		const selectedRows = this.selection.selected;
		var evaluateds = selectedRows.map((user) => {
			return {
				idEvaluated: user['idUser'],
				idJobProfile: user['jobProfileid'],
				idSubmodule: this.reportForm.value.evaluation,
				typeReport: 1,
			};
		});
		this.report.generateMassiveReports(evaluateds).subscribe((response: any) => {
			const { data, msg, ok } = response;
			if (ok) {
				Swal.fire(
					'Generando Reportes',
					'Sus reportes se encuentran en proceso, se le notificará por correo electrónico para su descarga.',
					'success'
				);
			}
			this.selection.clear();
		});
	}
	formatEmail(email: string): string {
		if (email === null || email === undefined) return '';
		const atIndex = email.indexOf('@');
		if (atIndex === -1) return email; // Si no hay arroba, retorna el correo sin cambios.
		return email.substring(0, atIndex) + '@...'; // Añade puntos después del arroba.
	}
}
