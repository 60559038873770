import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ClipboardService } from 'ngx-clipboard';
import { ConfigProyectComponent } from '../../../ethics-survey/config-proyect/config-proyect.component';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';
import Swal from 'sweetalert2';

// export interface DialogData {
//   link: string
// }

@Component({
  selector: 'app-link-proyect',
  templateUrl: './link-proyect.component.html',
  styleUrls: ['./link-proyect.component.css']
})
export class LinkProyectComponent implements OnInit {

  elementType = 'url';
  id : number = 0 ;
  constructor(    
    public childDialogRef: MatDialogRef<LinkProyectComponent>,
    public parentDialogRef : MatDialogRef<ConfigProyectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {idSurvey: number},
    @Inject(MAT_DIALOG_DATA) public dataf:  MatDialogRef<ConfigProyectComponent>,

    private conector: ConnectorEthicsSurveyService, ) { 
    this.id = data.idSurvey;
    this.parentDialogRef =  dataf;
    // private clipboardService: ClipboardService
   }

  ngOnInit() {
  }
  onNoClick(): void {
    this.childDialogRef.close();
    this.parentDialogRef.close();
  }
  // copyContent() {
  //   this.clipboardService.copy(this.data.link)
  // }

}
