import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: 'root',
})
export class ConnectorGroupService {
	constructor(private http: HttpClient) {}

	group_tree(): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem('token'),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/tree`, { headers }).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	import_users(data) {
		return this.http.post(`${environment.server}/users/import`, data);
	}

	getAllGroupsEval(): Observable<any> {
		const headers = new HttpHeaders({
			App: "evaluation",
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/all`, {headers}).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getAllGroups(): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem("token"),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/all`, {headers}).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getAllSubGroupsByGroupId(groupId: number): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: localStorage.getItem("token"),
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/subgroups/${groupId}`, {headers}).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getAllSubGroupsByGroupIdEval(groupId: number): Observable<any> {
		const headers = new HttpHeaders({
			App: "evaluation",
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/subgroups/${groupId}`, {headers}).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getGroupsByProjectId(nickname: string): Observable<any> {
		const headers = new HttpHeaders({
			App: "evaluation",
			'Content-type': 'application/json',
		});
		// const headers = new HttpHeaders({
		// 	Authorization: localStorage.getItem("token_evaluaciones"),
		// 	'Content-type': 'application/json',
		// });

		return this.http.get(`${environment.server}/groups/GroupsProy/${nickname}`, {headers}).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getAllSubGroupsByGroupIdProy(groupId: number, nickname: string): Observable<any> {
		const headers = new HttpHeaders({
			App: "evaluation",
			'Content-type': 'application/json',
		});

		return this.http.get(`${environment.server}/groups/subgroupsProy/${groupId}/${nickname}`, {headers}).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	  }
}
