import { FlatTreeControl } from "@angular/cdk/tree";
import { Injectable } from "@angular/core";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material";

@Injectable({
    providedIn: 'root'
})

export class Step3 {
    transformer = (node: FoodNode, level: number) => {
        return {
        expandable: !!node.subs && node.subs.length > 0,
        name: node.name,
        id: node.id,
        count: node.count,
        level: level,
        evalAsign: node.evalAsign
        };
    }
    treeControl = new FlatTreeControl<ExampleFlatNode>(node => node.level, node => node.expandable);
    treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.subs);
    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
}


// arbol 
export interface FoodNode {
    name: string;
    id: string;
    count?: number;
    subs?: FoodNode[];
    evalAsign?:number;
}

export interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    id: string;
    count: number;
    level: number;
    evalAsign:number;
}
