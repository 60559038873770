import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "../../environments/environment";
import DbResponseHandler from "../types/common/db-response-handler";
import DbMyUserProfile from "../types/db-my-user-profile-response";
import User from "../types/db-user-profile-response";

@Injectable({
  providedIn: "root",
})
export class ConectorJsonService {
  internalSocket: BehaviorSubject<any> = new BehaviorSubject(null);
  evaluationHeader: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  /* Auth */

  validaCompania(data) {
    return this.http.post(`${environment.server}/login/company`, data);
  }

  validarAcceso(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.http.post(`${environment.server}/login/auth`, data, {
      headers,
    });
  }

  getNamesOfAuthentication() {
    return this.http.get(
      `${environment.server}/JobProfile/get-names-of-authentication`
    );
  }

  get_simpleAuth(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.http.post(`${environment.server}/login/simple`, data, {
      headers,
    });
  }

  resetPassword(data) {
    return this.http.post(`${environment.server}/login/recovery`, data);
  }

  resetComplaint(data) {
    return this.http.post(`${environment.server}/login/complaint`, data);
  }

  renewPassword(data) {
    return this.http.post(`${environment.server}/login/reset`, data);
  }

  renewComplaint(data) {
    return this.http.post(`${environment.server}/login/complaint/reset`, data);
  }

  validate_enterprise(code) {
    return this.http.post(`${environment.server}/login/enterprise`, {
      application: code,
    });
  }

  renovarToken(hash) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.http.get(`${environment.server}/login/renew/${hash}`, {
      headers,
    });
  }

  log_out(hash) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.http.get(`${environment.server}/signout/${hash}`, { headers });
  }

  //
  savePath(data) {
    return this.http.post(`${environment.server}/savepoint`, data);
  }

  obtenerCompanias(page, limit) {
    return this.http.get(
      `${environment.server}/company/?page=${page}&offset=${limit}`
    );
  }

  obtenerPermisos(page, limit) {
    return this.http.get(
      `${environment.server}/permission/?page=${page}&offset=${limit}`
    );
  }

  user_permission() {
    return this.http.get(`${environment.server}/permission/me`);
  }

  modules_by_user() {
    return this.http.get(`${environment.server}/permission/modulesbyuser`);
  }

  update_modules(formdata) {
    return this.http.put(
      `${environment.server}/permission/modulesbyuser`,
      formdata
    );
  }

  extend_permissions(moduleName) {
    return this.http.post(
      `${environment.server}/permission/modulePermissions`,
      {
        module: moduleName,
      }
    );
  }

  /* Metodos */
  reporte_validarCompany(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.http.post(
      `${environment.server}/reporter/company`,
      { reporting: data },
      { headers }
    );
  }

  reporte_validaToken(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    });

    return this.http.post(
      `${environment.server}/reporter/token`,
      { reporting: data },
      { headers }
    );
  }

  reporte_relations(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
    });

    return this.http.get(`${environment.server}/reporter/relations`, {
      headers: headers,
    });
  }

  reporte_tree(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
    });

    return this.http.get(`${environment.server}/reporter/treeGroups`, {
      headers: headers,
    });
  }

  reporte_category_description(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
    });

    return this.http.get(
      `${environment.server}/reporter/category/${formdata.group}/description`,
      {
        headers: headers,
      }
    );
  }

  reporte_groups(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
    });

    return this.http.get(`${environment.server}/reporter/groups`, {
      headers: headers,
    });
  }

  get_category() {
    return this.http.get(`${environment.server}/categories`);
  }

  getDefaultFeedbackMessageCategory() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(
      `${environment.server}/categories/GetDefaultFeedbackMessageCategory`,
      {
        headers,
      }
    );
  }

  post_category(formdata) {
    return this.http.post(`${environment.server}/categories`, formdata);
  }

  put_category(formdata) {
    return this.http.put(
      `${environment.server}/categories/${formdata.id}`,
      formdata
    );
  }

  del_categories(formdata) {
    return this.http.delete(`${environment.server}/categories/${formdata}`);
  }

  parent_category() {
    return this.http.get(`${environment.server}/categories/master`);
  }

  tree_category(page = 0, limit = 10) {
    return this.http.get(
      `${environment.server}/categories/tree?page=${page}&size=${limit}`
    );
  }

  getByRelation(id) {
    return this.http.get(
      `${environment.server}/categories/GetByRelation/${id}`
    );
  }

  single_category(row) {
    return this.http.get(`${environment.server}/categories/${row}`);
  }

  children_category(row, page = 0, limit = 5) {
    return this.http.get(
      `${environment.server}/categories/${row}/childs?page=${page}&size=${limit}`
    );
  }

  category_allHandlers() {
    return this.http.get(`${environment.server}/categories/handlers`);
  }

  /* Preguntas asignadas a la categoria */

  category_questions(row) {
    return this.http.get(`${environment.server}/categories/${row}/questions`);
  }

  category_questions_post(formdata) {
    return this.http.post(
      `${environment.server}/categories/${formdata.group}/questions`,
      formdata
    );
  }

  category_questions_del(row) {
    return this.http.delete(
      `${environment.server}/categories/${row.group}/questions/${row.id}`
    );
  }

  /* Responsables asignados a la categoria */

  category_handlers(row) {
    return this.http.get(`${environment.server}/categories/${row}/handlers`);
  }

  category_handlers_post(formdata) {
    return this.http.post(
      `${environment.server}/categories/${formdata.group}/handlers`,
      formdata
    );
  }

  category_handlers_del(row) {
    return this.http.delete(
      `${environment.server}/categories/${row.group}/handlers/${row.id}`
    );
  }

  reporte_categories(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
    });

    return this.http.get(`${environment.server}/reporter/categories`, {
      headers: headers,
    });
  }

  reporte_subcategories(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
    });

    return this.http.get(
      `${environment.server}/reporter/subcategories/${formdata.category}`,
      {
        headers: headers,
      }
    );
  }

  report_questions(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
      App: "Denuncia",
    });

    return this.http.post(
      `${environment.server}/reporter/questions`,
      formdata,
      {
        headers: headers,
      }
    );
  }

  report_questions_base(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
      App: "Denuncia",
    });

    return this.http.post(
      `${environment.server}/reporter/questionsBase`,
      formdata,
      {
        headers: headers,
      }
    );
  }

  report_create(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
      App: "Denuncia",
    });

    return this.http.post(`${environment.server}/reporter/create`, formdata, {
      headers: headers,
    });
  }

  report_save_questions(formdata, reporting) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: reporting,
    });

    return this.http.post(
      `${environment.server}/reporter/create/questions`,
      formdata,
      {
        headers: headers,
      }
    );
  }

  report_user(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: formdata.reporting,
    });

    return this.http.post(`${environment.server}/reporter/user`, formdata, {
      headers: headers,
    });
  }

  report_upload(formdata, reporting, lang = "es-ES") {
    const headers = new HttpHeaders({
      Authorization: reporting,
    });

    return this.http.post(
      `${environment.server}/reporter/store?lang=` + lang,
      formdata,
      {
        headers: headers,
      }
    );
  }

  report_attachments(formdata, reporting) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: reporting,
    });

    return this.http.post(
      `${environment.server}/reporter/create/attachments`,
      formdata,
      {
        headers: headers,
      }
    );
  }

  /**********************************************************************
  ****************************** GROUPS *********************************
  /*********************************************************************/

  /* @params
   * JSONObject{ params, name, description }
   * @return JSONObject group
   */
  groups_post(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.post(`${environment.server}/groups`, formdata, {
      headers: headers,
    });
  }

  /* @params
   * JSONObject<Group>
   * @return JSONObject group
   */
  groups_put(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(
      `${environment.server}/groups/${formdata.id}`,
      formdata,
      { headers }
    );
  }

  groups_delete(item) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.delete(`${environment.server}/groups/${item}`, {
      headers,
    });
  }

  groups_get(page = 0, size = 5, filter = "") {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(
      `${environment.server}/groups?page=${page}&size=${size}&filter=${filter}`,
      { headers }
    );
  }

  groups_mix_get() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(`${environment.server}/groups/tree`, { headers });
  }

  groups_all() {
    return this.http.get(`${environment.server}/groups/all`);
  }

  /* @params
   * Integer rowId
   * @return JSONObject group
   */
  groups_single(id: number) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(`${environment.server}/groups/${id}`, { headers });
  }

  /* @params
   * Integer rowId
   * @return JSONArray< groups >
   */
  groups_childs(id: number, page = 0, size = 5) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(
      `${environment.server}/groups/${id}/childs?page=${page}&limit=${size}`,
      { headers }
    );
  }

  /**********************************************************************
  ****************************** Relations ******************************
  /*********************************************************************/

  relations_get(page = 0, limit = 10) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(
      `${environment.server}/relations?page=${page}&limit=${limit}`,
      {
        headers,
      }
    );
  }

  relations_put(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(`${environment.server}/relations/${data.id}`, data, {
      headers,
    });
  }

  relations_post(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.post(`${environment.server}/relations`, data, { headers });
  }

  relations_delete(item) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.delete(`${environment.server}/relations/${item}`, {
      headers,
    });
  }

  /**********************************************************************
  ****************************** Questions ******************************
  /*********************************************************************/

  questions_get(page = 0, size = 5) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(
      `${environment.server}/questions?page=${page}&offset=${size}`,
      {
        headers,
      }
    );
  }

  questions_get_list() {
    return this.http.get(`${environment.server}/questions/list`);
  }

  questions_put(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(`${environment.server}/questions/${data.id}`, data, {
      headers,
    });
  }

  questions_post(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.post(`${environment.server}/questions`, data, { headers });
  }

  questions_delete(item) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.delete(`${environment.server}/questions/${item}`, {
      headers,
    });
  }

  /**********************************************************************
  ************************** Questions Config ***************************
  /*********************************************************************/

  handlers_get() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(`${environment.server}/groups-config/users`, {
      headers,
    });
  }

  groups_questions_get(group = 0) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(
      `${environment.server}/groups-config/questions?groupId=${group}`,
      {
        headers,
      }
    );
  }

  group_questions_post(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.post(
      `${environment.server}/groups-config/question`,
      data,
      { headers }
    );
  }

  group_questions_del(item) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.delete(
      `${environment.server}/groups-config/question/${item}`,
      {
        headers,
      }
    );
  }

  groups_handlers_get(group = 0) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(
      `${environment.server}/groups-config/handlers?groupId=${group}`,
      {
        headers,
      }
    );
  }

  group_handlers_post(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.post(`${environment.server}/groups-config/handler`, data, {
      headers,
    });
  }

  group_handlers_del(item) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.delete(
      `${environment.server}/groups-config/handler/${item}`,
      { headers }
    );
  }

  /**********************************************************************
  *********************** Configuration own company *********************
  /*********************************************************************/

  cfg_get() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(`${environment.server}/company/cfg`, { headers });
  }

  cfg_put(formdata) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(`${environment.server}/company/cfg`, formdata, {
      headers,
    });
  }

  industries_get(all = "") {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(`${environment.server}/industries/${all}`, {
      headers,
    });
  }

  sectors_get(all = "") {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.get(`${environment.server}/sectors/${all}`, { headers });
  }

  /**********************************************************************
  *********************** Configuration own company *********************
  /*********************************************************************/

  company_put(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(`${environment.server}/company/${data.id}`, data, {
      headers,
    });
  }

  company_logo(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
    });
    return this.http.post(`${environment.server}/company/store`, formdata, {
      headers: headers,
    });
  }

  post_hiringField(data) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(
      `${environment.server}/company/PutHiringCompany/${data.id}`,
      data,
      {
        headers,
      }
    );
  }

  company_tycos(company) {
    return this.http.get(`${environment.server}/reporter/tycos/${company}`);
  }

  unlinkHiring() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(`${environment.server}/company/unlinkHiring`, {
      headers,
    });
  }

  /**********************************************************************
  ****************************** USERS **********************************
  /*********************************************************************/

  users_get(checked = 0, init = 0, limit = 5, filter = "") {
    if (filter === null) {
      filter = "";
    }

    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/users?limit=${limit}&offset=${init}&active=${checked}&filter=${filter}`,
      { headers }
    );
  }
  PermissionForAccount(
    checked = 0,
    init = 0,
    limit = 5,
    filter = "",
    idPrimary = "",
    type = 0
  ) {
    if (filter === null) {
      filter = "";
    }
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/users/PermissionForAccount?limit=${limit}&offset=${init}&active=${checked}&filter=${filter}&idPrimary=${idPrimary}&type=${type}`,
      { headers }
    );
  }

  PermissionForAccountSet(formdata, all = 0) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.put(
      `${environment.server}/users/PermissionForAccountSet/${formdata.idPrimary}/${formdata.id}/${formdata.futures}/${all}`,
      { headers }
    );
  }

  PermissionForAccountSetFutures(id = 0, futures = false) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.put(
      `${environment.server}/users/PermissionForAccountFutures/${id}/${futures}`,
      { headers }
    );
  }

  public userUpdate(user: User): Observable<DbResponseHandler<User>> {
    return this.http.put<DbResponseHandler<User>>(
      `${environment.server}/users/${user.id}`,
      user,
      {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("token"),
          "Content-type": "application/json",
        }),
      }
    );
  }
  public userPost(user: User): Observable<DbResponseHandler<User>> {
    return this.http.post<DbResponseHandler<User>>(
      `${environment.server}/users`,
      user,
      {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem("token"),
          "Content-type": "application/json",
        }),
      }
    );
  }

  users_put(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.put(`${environment.server}/users`, formdata, { headers });
  }

  users_del(rowId) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.delete(`${environment.server}/users/${rowId}`, {
      headers,
    });
  }

  login_put(formdata) {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.put(`${environment.server}/users/password`, formdata, {
      headers,
    });
  }

  /**********************************************************************
  ****************************** Handler functions **************************
  /*********************************************************************/

  handler_tickets(page = 0, limit = 0) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/handler/tickets?page=${page}&limit=${limit}`,
      {
        headers,
      }
    );
  }

  handler_ticket_single(id) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(`${environment.server}/handler/ticket/${id}`, {
      headers,
    });
  }

  handler_ticket(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.put(
      `${environment.server}/handler/ticket/${formdata.id}`,
      formdata,
      {
        headers,
      }
    );
  }

  handler_criticality(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.put(
      `${environment.server}/handler/ticket/${formdata.id}/criticality`,
      formdata,
      { headers }
    );
  }

  handler_chart() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(`${environment.server}/handler/myChart`, { headers });
  }

  handler_criticalityChart() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(`${environment.server}/handler/myCriticality`, {
      headers,
    });
  }

  handler_upload(formdata) {
    return this.http.post(`${environment.server}/handler/store`, formdata);
  }

  handler_get_activity(id) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(`${environment.server}/handler/activity/${id}`, {
      headers,
    });
  }

  handler_post_activity(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.post(`${environment.server}/handler/activity`, formdata, {
      headers,
    });
  }

  /**********************************************************************
  ****************************** supervisor functions **************************
  /*********************************************************************/

  supervisor_chart(inicio = null, final = null) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    let options = "";

    if (inicio !== null && final != null) {
      options = `inicio=${inicio}&final=${final}`;
    }

    return this.http.get(`${environment.server}/supervisor/chart?${options}`, {
      headers,
    });
  }

  generate_pdf(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(
      `${environment.server}/supervisor/generate`,
      formdata,
      { headers }
    );
  }

  generate_pdfModel(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(`${environment.server}/model`, formdata, { headers });
  }

  /**********************************************************************
  ******************************* permissons ****************************
  /*********************************************************************/

  modules_get() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.get(`${environment.server}/permission/modules`, {
      headers,
    });
  }

  modules_update(data) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(
      `${environment.server}/permission/syncPermissions`,
      data,
      {
        headers,
      }
    );
  }

  modules_user(formdata) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(
      `${environment.server}/permission/getPermissions`,
      formdata,
      {
        headers,
      }
    );
  }

  /**********************************************************************
  ****************************** Reporting data *************************
  /*********************************************************************/

  report_auth(formdata) {
    const headers = new HttpHeaders({
      "Content-type": "application/json",
    });
    return this.http.post(`${environment.server}/reporter/auth`, formdata, {
      headers,
    });
  }

  ticket_information() {
    return this.http.get(`${environment.server}/reporter/ticket`);
  }

  publish_message(formdata) {
    return this.http.post(`${environment.server}/reporter/comments`, formdata);
  }

  ticket_upload(formdata) {
    return this.http.post(
      `${environment.server}/reporter/ticket_upload`,
      formdata
    );
  }

  /**********************************************************************
  ****************************** Evaluaciones ***************************
  /*********************************************************************/

  postEvaluation_login(formdata) {
    console.log(formdata);

    return this.http.post(environment.server + "/evaluation/auth", formdata, {
      headers: { App: "evaluation" },
    });
  }

  getEvaluation_profile() {
    return this.http.get(environment.server + "/evaluation/profile", {
      headers: { App: "evaluation" },
    });
  }

  putEvaluation_profile(formdata) {
    return this.http.put(environment.server + "/evaluation/profile", formdata, {
      headers: { App: "evaluation" },
    });
  }

  getEvaluation() {
    return this.http.get(environment.server + "/evaluation", {
      headers: { App: "evaluation" },
    });
  }

  postEvaluation(formdata) {
    return this.http.post(environment.server + "/evaluation", formdata, {
      headers: { App: "evaluation" },
    });
  }

  cognitiveSkillsTimeOut(body) {
    return this.http.post(
      `${environment.server}/evaluation/cognitiveSkillsTimeOut`,
      body,
      {
        headers: { App: "evaluation" },
      }
    );
  }

  fetchEvaluation(formdata: any) {
    let token = localStorage.getItem("token_evaluaciones");

    fetch(environment.server + "/evaluation", {
      method: "POST",
      body: JSON.stringify(formdata),
      headers: {
        App: "evaluation",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  }
  getExitSurveyByEvaluated(evaluatedId: number) {
    const headers = new HttpHeaders({
      "Content-type": "application/json",
    });
    return this.http.get(
      `${environment.server}/evaluation/getExitSurveyByEvaluated/${evaluatedId}`,
      {
        headers,
      }
    );
  }

  asignExitSurvey(evaluatedId: number, formdata: any) {
    const headers = new HttpHeaders({
      "Content-type": "application/json",
    });
    return this.http.post(
      `${environment.server}/evaluation/asignExitSurvey/${evaluatedId}`,
      formdata,
      {
        headers,
      }
    );
  }
  /**********************************************************************
  ****************************** Generic Tools **************************
  /*********************************************************************/

  private_file(file) {
    return this.http.get(`${environment.base}/private/files/${file}`, {
      responseType: "blob",
    });
  }

  getToken() {
    return "";
  }

  get_user_ip() {
    return this.http.get(`${environment.base}/api/v1/login/me`);
  }

  /**********************************************************************
  **************************** EVALUATION CONFIG ************************
  /*********************************************************************/

  get_eConfig(init = 0, limit = 10) {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(
      `${environment.base}/api/v1/evaluationconfig?limit=${limit}&offset=${init}`
    );
  }
  getAllSurveys() {
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });

    return this.http.get(
      `${environment.base}/api/v1/evaluationconfig/getAllSurveys`
    );
  }

  post_eConfig(data) {
    return this.http.post(`${environment.base}/api/v1/evaluationconfig`, data);
  }
  updateSurvey(data) {
    return this.http.put(
      `${environment.base}/api/v1/evaluationconfig/updateSurvey/${data["id"]}`,
      data
    );
  }

  get_eConfingSections(row) {
    return this.http.get(
      `${environment.base}/api/v1/evaluationconfig/${row}/sections`
    );
  }

  post_eConfigSections(row, data) {
    return this.http.post(
      `${environment.base}/api/v1/evaluationconfig/${row}/sections`,
      data
    );
  }

  put_eConfigSection(row, data) {
    return this.http.put(
      `${environment.base}/api/v1/evaluationconfig/sections/${row}`,
      data
    );
  }

  get_eConfigQuestions(row) {
    return this.http.get(
      `${environment.base}/api/v1/evaluationconfig/${row}/questions`
    );
  }

  post_eConfigQuestions(row, data) {
    return this.http.post(
      `${environment.base}/api/v1/evaluationconfig/${row}/questions`,
      data
    );
  }

  put_eConfigQuestions(row, data) {
    return this.http.put(
      `${environment.base}/api/v1/evaluationconfig/questions/${row}`,
      data
    );
  }

  get_eConfigAnswers(question) {
    return this.http.get(
      `${environment.base}/api/v1/evaluationconfig/${question}/answers`
    );
  }

  post_eConfigAnswers(question, data) {
    return this.http.post(
      `${environment.base}/api/v1/evaluationconfig/${question}/answers`,
      data
    );
  }

  get_eConfigQuestionTypes() {
    return this.http.get(
      `${environment.base}/api/v1/evaluationconfig/questionTypes`
    );
  }

  del_eConfigSections(row) {
    return this.http.delete(
      `${environment.base}/api/v1/evaluationconfig/sections/${row}`
    );
  }

  del_eConfigQuestion(row) {
    return this.http.delete(
      `${environment.base}/api/v1/evaluationconfig/questions/${row}`
    );
  }

  del_eConfigAnswer(row) {
    return this.http.delete(
      `${environment.base}/api/v1/evaluationconfig/answers/${row}`
    );
  }
  downloadGroupReportExit(data) {
    console.log(data);
    const headers = new HttpHeaders({
      Authorization: localStorage.getItem("token"),
      "Content-type": "application/json",
    });
    return this.http.post(
      `${environment.base}/api/v1/evaluationconfig/downloadGroupReport/`,
      data
    );
  }

  postLanguage(lng) {
    return this.http.put(`${environment.base}/api/v1/language`, lng);
  }

  getLanguage() {
    return this.http.get(`${environment.base}/api/v1/language`);
  }

  tycos() {
    return this.http.get(`${environment.base}/user/tycos`);
  }

  tycos_put(data) {
    return this.http.put(`${environment.base}/user/tycos`, data);
  }

  profile_put(data) {
    return this.http.post(`${environment.base}/user/tycos`, data);
  }

  reporte_valida_puesto(data) {
    return this.http.post(`${environment.base}/evaluation/todo`, data);
  }

  reporte_valida_usuario(data) {
    return this.http.post(`${environment.base}/evaluation/myEvaluation`, data);
  }

  my_profile() {
    return this.http.get(`${environment.base}/user/configuration`);
  }

  my_profile_put(data) {
    return this.http.put(`${environment.base}/user/configuration`, data);
  }

  save_photo(data) {
    return this.http.post(`${environment.base}/evaluation/photo`, data, {
      headers: { App: "evaluation" },
    });
  }

  save_photo_profile(data) {
    return this.http.post(
      `${environment.base}/evaluation/profile/photo`,
      data,
      {
        headers: { App: "evaluation" },
      }
    );
  }

  send_options(data) {
    return this.http.post(`${environment.server}/company/options`, data);
  }

  get_options() {
    return this.http.get(`${environment.server}/company/options`);
  }

  delete_options(row) {
    return this.http.delete(`${environment.server}/company/options/${row}`);
  }

  /** v2 **/
  get_category_relations(data) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: data.reporting,
    });

    return this.http.get(
      `${environment.server}/reporter/relations/${data.relation}/categories`,
      {
        headers: headers,
      }
    );
  }

  get_subcategory_relations(data: any) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: data.reporting,
    });
    return this.http.get(
      `${environment.server}/reporter/relations/${data.relation}/subcategories/${data.category}`,
      { headers: headers }
    );
  }

  get_hiring_vacancy() {
    return this.http.get(`${environment.base}/user/hiringPuestos`);
  }

  get_mc_accounts() {
    return this.http.get(`${environment.server}/permission/multiaccounts`);
  }

  switch_ac(account: string) {
    return this.http.get(`${environment.server}/permission/switch/${account}`);
  }

  notifications() {
    return this.http.get(`${environment.server}/notifications`);
  }

  notifications_Read(id = 0) {
    return this.http.get(`${environment.server}/notifications/open/${id}`);
  }

  category_relations(id) {
    return this.http.get(`${environment.server}/categories/${id}/relations`);
  }

  category_add_relations(formdata) {
    return this.http.post(
      `${environment.server}/categories/${formdata.category}/relations`,
      formdata
    );
  }

  category_del_relations(formdata) {
    return this.http.delete(
      `${environment.server}/categories/${formdata.category}/relations/${formdata.id}`
    );
  }
  reset_email() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
      Authorization: localStorage.getItem("token"),
    });
    return this.http.put(`${environment.server}/company/resetEmail`, {
      headers,
    });
  }

  public getMyUserProfile(): Observable<DbResponseHandler<DbMyUserProfile>> {
    return this.http.get<DbResponseHandler<DbMyUserProfile>>(
      `${environment.base}/user/configuration`
    );
  }
  get_evaluated_backgroundcheck(idEvaluated) {
    return this.http.post(
      `${environment.server}/searchlook/evaluatedBackgroundcheck/`,
      { evaluationUID: idEvaluated }
    );
  }
  accept_privacy_policy_backgroundcheck(formData) {
    return this.http.put(
      `${environment.server}/searchlook/acceptPrivacyPolicyBackgroundcheck/`,
      formData
    );
  }
  decline_privacy_policy_backgroundcheck(formData) {
    return this.http.put(
      `${environment.server}/searchlook/declinePrivacyPolicyBackgroundcheck/`,
      formData
    );
  }

  getAllUsersRoleAdmin(idEvaluatedToDelete: number): Observable<any> {
    return this.http.get(
      `${environment.server}/Collaborator/allUsersRoleAdmin/${idEvaluatedToDelete}`
    );
  }

  transferAllInformationOtherUser(fromUserId: number, toUserId: number) {
    return this.http.put(
      `${environment.server}/Collaborator/transferAllInformationOtherUser`,
      {
        fromUserId,
        toUserId,
      }
    );
  }
}
