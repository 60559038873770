import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog, PageEvent } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, throwError } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import Swal from "sweetalert2";
import { ConectorModuleService } from "../../../services/conector-module.service";
import { TransferAllInformationOfAUserComponent } from "../modals/transfer-all-information-of-auser/transfer-all-information-of-auser.component";
import { Pagination } from "./../../../models/pagination";
import { AuthenticationService } from "./../../../services/authentication.service";
import { ConectorJsonService } from "./../../../services/conector-json.service";
import { CreateUserComponent } from "./../modals/create-user/create-user.component";
import { ManageUserComponent } from "./../modals/manage-user/manage-user.component";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public showActive: number = 0;
  public userForm: FormGroup;
  public length: number = 0;
  public users: [] = [];
  public displayedColumns: string[] = ["nickname", "name", "email", "actions"];
  activeRow = null;
  currentModule = null;
  filtering: any = null;
  $allUsersRegisteredBy: Observable<any[]>;

  pagination = new Pagination();
  permissionCreateUser: boolean = true;
  permissionUpdateUser: boolean = true;
  permissionChangePassword: boolean = true;
  permissionDeleteUser: boolean = true;
  translations: { [key: string]: string } = {
    "user-change-password": "",
    "button-text-confirm": "",
    "button-text-cancel": "",
    "action-text-register-modified": "",
    "action-text-error-modifying": "",
    "button-text-accept": "",
    "action-text-register-deleted": "",
    "user-alert-information-transferred": ""
  };

  constructor(
    private conector: ConectorJsonService,
    private conectorModuleService: ConectorModuleService,
    public dialog: MatDialog,
    private route: Router,
    public translate: TranslateService,
    public auth: AuthenticationService
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);
    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
    this.currentModule = localStorage.getItem("module");
    this.setLanguageAndGetTranslation();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.getUsers(1, this.pagination.init, this.pagination.pageSize);
  }

  public changePage($event: PageEvent): void {
    this.pagination.changePage($event);
    this.getUsers(0, $event.pageIndex, $event.pageSize, this.filtering);
  }

  private getUsers(
    requestType: number,
    pageIndex: number,
    pageSize: number,
    filter = ""
  ): void {
    this.users = [];
    this.pagination.length = 0;
    this.conector
      .users_get(requestType, pageIndex, pageSize, filter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((d: any) => {
        const { msg, data } = d;
        this.pagination.length = msg;
        this.users = data;
      });
  }
  searchUsers() {
    this.conector
      .users_get(this.showActive, 0, this.pagination.limit, this.filtering)
      .subscribe((d: any) => {
        const { msg, data } = d;
        this.pagination.length = msg;
        this.users = data;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  editUser(row) {
    const dialogRef = this.dialog.open(ManageUserComponent, { data: row });

    dialogRef.afterClosed().subscribe(() => {
      this.conector.users_get().subscribe((response: any) => {
        const { msg, data } = response;
        this.users = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
    });
  }
  async setLanguageAndGetTranslation(): Promise<void> {
    const language = await this.auth.setLanguage();
    this.translate.setDefaultLang(language);
    const translationPromises = Object.keys(this.translations).map(key => {
      return new Promise<void>((resolve, reject) => {
        this.translate.get(key).subscribe((res: string) => {
          this.translations[key] = res;
          resolve();
        });
      });
    });

    await Promise.all(translationPromises);
  }
  async editLogin(formdata) {
    Swal.fire({
      title: this.translations['user-change-password'],
      text: formdata.email,
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: this.translations['button-text-confirm'],
      cancelButtonText: this.translations['button-text-cancel'],
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 my-cancel-button general-styles-buttons',
        confirmButton: 'order-2 my-confirm-button general-styles-buttons',
      },
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        if (login !== null || login === "") {
          const model = {
            id: formdata.userlogin,
            password: login,
          };
          this.conector.login_put(model).subscribe(() => {
            Swal.fire({
              title: this.translations["action-text-register-modified"],
              icon: "success",
              confirmButtonText: this.translations['button-text-accept'],
              customClass: {
                actions: 'my-actions',
                confirmButton: 'order-2 general-styles-buttons my-confirm-button',
              },
            });
          });
        } else {
          Swal.fire({
            title: this.translations["action-text-error-modifying"],
            icon: 'error',
            confirmButtonText: this.translations["button-text-accept"],
            customClass: {
              actions: 'my-actions',
              confirmButton: 'order-2 general-styles-buttons my-confirm-button',
            },
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }

  adduser() {
    const ref = this.dialog.open(CreateUserComponent, {});
    ref.afterClosed().subscribe(() => {
      this.conector.users_get().subscribe((d: any) => {
        console.info("u&", d);
      });
    });
  }

  async editUser2(row) {
    localStorage.setItem("userPermission", JSON.stringify(row));
    const encodedId = encodeURIComponent(btoa(row.id.toString()));
    await this.route.navigate(["/manager/edit-user", encodedId]);
  }

  async setPermission(row) {
    localStorage.setItem("userPermission", JSON.stringify(row));
    await this.route.navigateByUrl("/manager/permissions");
  }

  async newUser() {
    localStorage.setItem("userPermission", JSON.stringify({}));
    await this.route.navigateByUrl("/manager/create-users");
  }

  openDialog(selectedUser: any): void {
    this.conector
      .getAllUsersRoleAdmin(selectedUser.id)
      .pipe(
        map((response) => response.data),
        switchMap((users) => this.openTransferDialog(users)),
        filter((result) => !!result),
        switchMap((result) => this.transferUserInfo(selectedUser, result)),
        tap((response) => this.checkTransferSuccess(response, selectedUser)),
        tap(() =>
          this.getUsers(1, this.pagination.init, this.pagination.pageSize)
        ),
        catchError((error) => this.handleError(error))
      )
      .subscribe();
  }

  openTransferDialog(users: any): Observable<any> {
    const dialogRef = this.dialog.open(TransferAllInformationOfAUserComponent, {
      width: "500px",
      data: { users: users },
    });
    return dialogRef.afterClosed();
  }

  transferUserInfo(selectedUser: any, result: any): Observable<any> {
    return this.conector.transferAllInformationOtherUser(
      selectedUser.id,
      result.id
    );
  }

  checkTransferSuccess(response: any, selectedUser: any) {
    if (response.ok) {
      this.finalizeTransfer(selectedUser);
    }
  }

  finalizeTransfer(selectedUser: any) {
    this.conector.users_del(selectedUser.id).subscribe({
      next: (response: any) => {
        if (!response.ok) return;
        Swal.fire({
          title: this.translations["action-text-register-deleted"],
          text: this.translations["user-alert-information-transferred"],
          icon: 'success',
          confirmButtonText: this.translations["button-text-accept"],
          customClass: {
            actions: 'my-actions',
            confirmButton: 'order-2 general-styles-buttons my-confirm-button',
          },
        });

      },
      error: (error) => this.handleError(error),
    });
  }

  handleError(error: any) {
    console.error("Error:", error);
    return throwError(() => new Error("An error occurred"));
  }

  getIdModuleLibrary() {
    const currentRoute = this.route.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }
  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "createUser") {
              this.permissionCreateUser = item.havePermission;
            } else if (item.code === "updateUser") {
              this.permissionUpdateUser = item.havePermission;
            } else if (item.code === "changePassword") {
              this.permissionChangePassword = item.havePermission;
            } else if (item.code === "deleteUser") {
              this.permissionDeleteUser = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
}
