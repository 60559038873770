import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ConnectorCollaboratorService } from "../../../services/Collaborator/conector-collaborator.service";
import { ConectorJsonService } from "../../../services/conector-json.service";
import { ConectorModuleService } from "../../../services/conector-module.service";
import { ConnectorJobProfileService } from "../../../services/job-profile.service";
import { CollaboratorSurveysComponent } from "../modals/collaborator-surveys/collaborator-surveys.component";
import { CreateManyCollaboratorComponent } from "../modals/create-many-collaborator/create-many-collaborator.component";
import { EvaluatedLinkComponent } from "../modals/evaluated-link/evaluated-link/evaluated-link.component";
import { ExitSurveyFormComponent } from "../modals/exit-survey-form/exit-survey-form.component";
import { ImportCollaboratorComponent } from "../modals/import-collaborator/import-collaborator.component";
import { SubmoduleCollaboratorComponent } from "../modals/submodule-collaborator/submodule-collaborator.component";
import { Pagination } from "./../../../models/pagination";
import { ConnectorGroupService } from "../../../services/Admin/conector-group.service";
import { SelectionModel } from "@angular/cdk/collections";
import { AssignEvaluationsMassiveComponent } from "../modals/assign-evaluations-massive/assign-evaluations-massive.component";

@Component({
  selector: "app-collaborator",
  templateUrl: "./collaborator.component.html",
  styleUrls: ["./collaborator.component.css"],
})
export class CollaboratorComponent implements OnInit {
  selectedIndex = 0;
  collaborators = [];
  jobProfileList = [];
  pagination = new Pagination();
  collaboratorForm: FormGroup;
  collaboratorSubmodules = [];
  collaboratorSelected = { id: 0, name: "", firstname: "" };
  evaluations = [];
  currentModule = null;

  permissionIndividualRegistration: boolean = true;
  permissionMultipleRegistration: boolean = true;
  permissionImportData: boolean = true;
  permissionUpdateEvaluated: boolean = true;
  permissionDirectLink: boolean = true;
  permissionAssignEvaluation: boolean = true;
  permissionSendEvaluation: boolean = true;
  permissionDeleteEvaluated: boolean = true;
  @ViewChild(CollaboratorSurveysComponent, { static: true })
  csc: CollaboratorSurveysComponent;
  displayedColumns: string[] = [
    "select",
    "nickname",
    "name",
    "email",
    "job",
    "actions",
  ];
  groupsCategoryCompany: any = [];
  subgroupsOfSelectedGroup: any = [];
  groupSelected: number = 0;
  subgroupSelected: number = 0;
  selection;

  constructor(
    private conector: ConnectorCollaboratorService,
    private reconect: ConectorJsonService,
    private conectorModuleService: ConectorModuleService,
    private groupService: ConnectorGroupService,
    private job: ConnectorJobProfileService,
    private moduleconnector: ConectorModuleService,
    public router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);
    this.pagination.length = 10;
    this.pagination.pageSize = 10;
    this.pagination.limit = 10;
    this.selection = new SelectionModel<any>(true, []);
  }

  ngOnInit() {
    this.collaboratorForm = this.fb.group({
      filtering: [null],
    });

    this.reload();

    // this.job.jobprofile_all().subscribe((response: any) => {
    //   const { ok, data, msg } = response;

    //   data.push({ id: 0, label: "-Seleccionar puesto-" });
    //   data.sort(function (a, b) {
    //     return a.id - b.id;
    //   });

    //   this.jobProfileList = data;
    // });

    // this.reconect
    //   .getAllSurveys()
    //   .subscribe((r: ResponseHandler) => (this.evaluations = r.data));
    this.groupService.getAllGroups().subscribe((groups) => {
      this.groupsCategoryCompany = [
        ...groups,
        { id: 0, name: "-Seleccionar grupo-" },
      ];
      this.groupsCategoryCompany.sort((a, b) => a.id - b.id);
    });
  }
  getSubgroupsOfSelectedGroup(seletedGroupId: number) {
    this.groupSelected = seletedGroupId;
    this.subgroupSelected = 0;
    this.subgroupsOfSelectedGroup = [];
    if (seletedGroupId != 0) {
      this.groupService
        .getAllSubGroupsByGroupId(seletedGroupId)
        .subscribe((subgroups) => {
          if (subgroups.length > 0) {
            this.subgroupsOfSelectedGroup = [
              ...subgroups,
              { id: 0, name: "-Seleccionar subgrupo-" },
            ];
            this.subgroupsOfSelectedGroup.sort((a, b) => a.id - b.id);
          }
        });
    }
  }
  getSubgroupSelected(selectedSubgroupId: number) {
    this.subgroupSelected = 0;
    if (selectedSubgroupId != 0) {
      this.subgroupSelected = selectedSubgroupId;
    }
  }

  reload() {
    this.conector
      .collaborator_get(
        this.collaboratorForm.value,
        this.pagination.init,
        this.pagination.limit
      )
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.collaborators = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  SearchCollaborators(formdata) {
    var groupId =
      this.subgroupSelected === 0 ? this.groupSelected : this.subgroupSelected;
    formdata.filteringByGroupOrSubgroup = groupId;
    this.conector
      .collaborator_get(formdata, this.pagination.init, this.pagination.limit)
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.collaborators = data;
        this.pagination.length = msg;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  changePage($event) {
    this.pagination.length = 0;
    this.collaborators = [];

    this.pagination.changePage($event);

    this.conector
      .collaborator_get(
        this.collaboratorForm.value,
        this.pagination.init,
        this.pagination.limit
      )
      .subscribe((d: any) => {
        const { msg, data } = d;
        this.pagination.length = msg;
        this.collaborators = data;
        this.pagination.pageSize = 10;
        this.pagination.limit = 10;
      });
  }

  SendEvaluation(row) {
    const dialogRef = this.dialog.open(SubmoduleCollaboratorComponent, {
      data: row,
      width: "600px",
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.conector
        .collaborator_get(this.collaboratorForm.value)
        .subscribe((response: any) => {
          const { ok, data, msg } = response;
          this.collaborators = data;
        });
    });
  }

  AsignEvaluation(row) {
    this.collaboratorSelected = row;
    this.moduleconnector
      .submodule_get(1, this.collaboratorSelected.id)
      .subscribe((e: any) => {
        this.collaboratorSubmodules = e.data;
        this.csc.openModal = true;
      });
  }

  updateEvaluations(e) {
    var selDom = e.filter((f) => f.id == 10);
    var selIA = e.filter((f) => f.id == 13);
    var selPerf = e.filter((f) => f.id == 3);
    var selHones = e.filter((f) => f.id == 1);

    // Valida que seleccionen perfiles & dominancia
    if (selPerf[0] != undefined && selDom[0] != undefined) {
      if (selPerf[0].selected == false && selDom[0].selected == true) {
        return Swal.fire(
          "Advertencia",
          "Para seleccionar Dominancia cerebral, necesario seleccionar Perfiles.",
          "warning"
        );
      }
    }

    // Valida que seleccionen honestidad & IA
    if (selHones[0] != undefined && selIA[0] != undefined) {
      if (selHones[0].selected == false && selIA[0].selected == true) {
        return Swal.fire(
          "Advertencia",
          "Para seleccionar el Reporte de Inteligencia Artificial, es necesario seleccionar Honestidad.",
          "warning"
        );
      }
    }

    const haveExitSurvey =
      e.filter((evaluation) => evaluation.id === 5 && evaluation.selected)
        .length > 0;

    if (haveExitSurvey) {
      const evaluationsWithoutExitSurvey = e.filter(
        (evaluation) => evaluation.id != 5
      );
      this.conector
        .collaborator_asign(
          this.collaboratorSelected.id,
          evaluationsWithoutExitSurvey
        )
        .subscribe((response: any) => {
          const { ok } = response;
          if (ok) {
            this.csc.openModal = false;
            const informationSurvey = {
              evaluatedId: this.collaboratorSelected.id,
              evaluatedName: `${this.collaboratorSelected.name} ${this.collaboratorSelected.firstname}`,
            };
            const dialogRef = this.dialog.open(ExitSurveyFormComponent, {
              data: informationSurvey,
              width: "700px",
            });
            dialogRef.afterClosed().subscribe((res) => {});
          } else {
            console.log("Error asigned evaluations");
          }
        });
    } else {
      this.conector
        .collaborator_asign(this.collaboratorSelected.id, e)
        .subscribe((response: any) => {
          const { ok } = response;
          if (ok) {
            this.csc.openModal = false;
            this.updateEvaluationsConfirm();
          } else {
            console.log("Error asigned evaluations");
          }
        });
    }
  }
  getAssignedSubmodules() {
    this.moduleconnector
      .submodule_get(1, this.collaboratorSelected.id)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          this.collaboratorSubmodules = data;
        } else {
          console.log("Error get assigned submodules");
        }
      });
  }

  async updateEvaluationsConfirm() {
    Swal.fire({
      title: await this.translate.get("success").toPromise(),
      text: await this.translate.get("evals-asigned").toPromise(),
      icon: "success",
      showCancelButton: false,
      confirmButtonText: " Ok ",
    });
  }

  AddCollaborator(row) {
    if (row.id == undefined) row.id = 0;

    this.router.navigateByUrl("/manager/collaborator/" + row.id);
  }

  AddManyCollaborators() {
    const dialogRef = this.dialog.open(CreateManyCollaboratorComponent, {
      data: null,
      width: "800px",
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.log("cierra ventana");

      this.reload();

      // this.conector.collaborator_get(this.collaboratorForm).subscribe((response: any) => {
      //   const { ok, data, msg } = response;
      //   this.collaborators = data;
      //   this.pagination.length = msg;
      //   this.pagination.pageSize = 10;
      //   this.pagination.limit = 10;
      // });
    });
  }

  ImportCollaborators() {
    const dialogRef = this.dialog.open(ImportCollaboratorComponent, {
      data: null,
      width: "800px",
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.log("cierra ventana");

      this.reload();

      // this.conector.collaborator_get(this.collaboratorForm).subscribe((response: any) => {
      //   const { ok, data, msg } = response;
      //   this.pagination.length = msg;
      //   this.collaborators = data;
      //   this.pagination.pageSize = 10;
      //   this.pagination.limit = 10;
      // });
    });
  }

  EraseCollaborator(row) {
    if (!row.haveCompletedEvaluations) {
      Swal.fire({
        title: "Advertencia",
        icon: "warning",
        text: "Esta acción es irreversible",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
      }).then((d: any) => {
        if (d.value === true) {
          this.conector.collaborator_delete(row.id).subscribe((x: any) => {
            const { ok, msg } = x;
            if (ok === true) {
              Swal.fire("Exito", "Eliminación realizada.", "success").then(
                (d: any) => {
                  if (d.value === true) {
                    this.conector
                      .collaborator_get(
                        this.collaboratorForm.value,
                        this.pagination.init,
                        this.pagination.limit
                      )
                      .subscribe((response: any) => {
                        const { ok, data, msg } = response;
                        this.collaborators = data;
                        this.pagination.length = msg;
                        this.pagination.pageSize = 10;
                        this.pagination.limit = 10;
                      });
                  }
                }
              );
            } else {
              Swal.fire("Error", msg, "error");
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "No es posible eliminar el evaluado.",
        text: "Debido a que tiene evaluaciones finalizadas.",
        icon: "error",
      });
    }
  }

  DirectLink(user) {
    let protocol = window.location.port ? `:${window.location.port}` : "";

    if (user.evaluation_uid) {
      const linkTo = `${window.location.protocol}//${window.location.hostname}${protocol}/my-evaluation/${user.evaluation_uid}`;

      /*Swal.fire({
        title: 'Enlace directo',
        icon: 'info',
        html: `<div style="font-size:12px">Comparte este enlace para acceder directamente a la evaluación <br/> ${linkTo}</div>`
      });*/

      const dialogRef = this.dialog.open(EvaluatedLinkComponent, {
        data: linkTo,
      });
    } else {
      Swal.fire(
        "Error",
        "Aun no cuentas con un token, generarlo para obtener un link",
        "error"
      );
    }
  }

  RequestReport(user) {
    if (user.name === null || user.name === "") {
      Swal.fire({
        title: "Advertencia",
        icon: "warning",
        text: "Los siguientes campos son requeridos para generar el reporte: Nombre, Apellidos ¿Desea capturar la información faltante?",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then((d: any) => {
        if (d.value === true) {
          if (user.id == undefined) user.id = 0;
          this.router.navigateByUrl("/manager/collaborator/" + user.id);
        }
        // else
        // {
        //   this.SendRequestReport(user);
        // }
      });
    } else {
      this.SendRequestReport(user);
    }
  }

  SendRequestReport(user) {
    this.conector.requestReport(user.id).subscribe(async (e: any) => {
      if (e != null && e.ok == true) {
        Swal.fire(
          "Completado",
          "Se envió la solicitud exitosamente. El reporte puede tardar hasta 24 horas hábiles en estar disponible. Recibirá una notificación por correo cuando esté listo para su descarga.",
          "success"
        );
      }
      if (e.ok == false && e.msg == "Faltan datos") {
        Swal.fire({
          title: "Advertencia",
          icon: "warning",
          text: "Los siguientes campos son requeridos para generar el reporte: Nombre, Apellidos ¿Desea capturar la información faltante?",
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then((d: any) => {
          if (d.value === true) {
            if (user.id == undefined) user.id = 0;
            this.router.navigateByUrl("/manager/collaborator/" + user.id);
          }
        });
      } else if (e.ok == false) {
        Swal.fire("Advertencia", e.msg, "warning");
      }
    });
  }

  ResponseReport(user) {
    this.conector.responseReport(user.id).subscribe(async (e: any) => {
      if (e.ok == true) {
        Swal.fire("Notificación", e.data.message, "success");
      }
      if (e.ok == false) {
        Swal.fire("Advertencia", e.msg, "warning");
      }
    });
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }
  toggleAllRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.collaborators.forEach((row) => {
          this.selection.select(row);
        });
  }
  assingEvaluationsMassive() {
    const evaluatedIds = this.selection.selected.map((item) => item.id);
    const dialogRef = this.dialog.open(AssignEvaluationsMassiveComponent, {
      data: evaluatedIds,
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.selection.clear();
      this.reload();
    });
  }

  getIdModuleLibrary() {
    const currentRoute = this.router.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }
  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "individualRegistrationEvaluated") {
              this.permissionIndividualRegistration = item.havePermission;
            } else if (item.code === "multipleRegistrationEvaluated") {
              this.permissionMultipleRegistration = item.havePermission;
            } else if (item.code === "importDataEvaluated") {
              this.permissionImportData = item.havePermission;
            } else if (item.code === "updateEvaluated") {
              this.permissionUpdateEvaluated = item.havePermission;
            } else if (item.code === "directLinkEvaluated") {
              this.permissionDirectLink = item.havePermission;
            } else if (item.code === "assignEvaluation") {
              this.permissionAssignEvaluation = item.havePermission;
            } else if (item.code === "sendEvaluation") {
              this.permissionSendEvaluation = item.havePermission;
            } else if (item.code === "deleteEvaluated") {
              this.permissionDeleteEvaluated = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
}
