import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GenericCatalogsService } from "../../../../services/GenericCatalogs.service";
import { ConnectorCollaboratorService } from "../../../../services/Collaborator/conector-collaborator.service";
import Swal from "sweetalert2";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "app-assign-evaluations-massive",
  templateUrl: "./assign-evaluations-massive.component.html",
})
export class AssignEvaluationsMassiveComponent
  implements AfterViewInit, OnDestroy
{
  evaluatedIds: number[];
  submodules: ISubmodules[];
  showMessageEvaluations: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number[],
    private catalogService: GenericCatalogsService,
    private collaboratorService: ConnectorCollaboratorService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AssignEvaluationsMassiveComponent>
  ) {
    this.evaluatedIds = data;
  }

  ngAfterViewInit(): void {
    Promise.resolve().then(() => {
      this.getallsubmodules();
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private getallsubmodules(): void {
    this.catalogService
      .getSubmodulesForAssignEvaluationsMassive()
      .subscribe((response) => {
        if (!response.ok) {
          console.log("Error get submodules for assign evaluations massive");
          return;
        }
        this.submodules = response.data.map((submodule) => ({
          ...submodule,
          selected: false,
        }));
        this.cdr.detectChanges();
      });
  }
  assignEvaluations(): void {
    Swal.fire({
      title: "Asignando Evaluaciones",
      text: "Espere un momento...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const selectedEvaluations = this.submodules
      .filter((submodule) => submodule.selected)
      .map((submodule) => submodule.id);

    const information: MultiEvaluationAssignmentModel = {
      evaluatedIds: this.evaluatedIds,
      evaluationIds: selectedEvaluations,
    };
    this.collaboratorService
      .assignMultipleEvaluations(information)
      .subscribe((response) => {
        if (!response.ok) {
          console.log("Error assign evaluations massive");
          Swal.close();
          Swal.fire("Error", response.msg, "error");
          return;
        }
        Swal.close();
        Swal.fire("", "Asignación correcta", "success");
        this.dialogRef.close();
      });
  }

  get isAssignButtonDisabled(): boolean {
    return (
      !this.submodules ||
      this.submodules.filter((item) => item.selected).length === 0
    );
  }

  changeSubmodule(row: any): void {
    const evaluationsWithConfig = [1, 3];
    this.showMessageEvaluations = this.submodules.some(
      (item) => item.selected && evaluationsWithConfig.includes(item.id)
    );
  }
}

interface MultiEvaluationAssignmentModel {
  evaluationIds: number[];
  evaluatedIds: number[];
}
interface ISubmodules {
  id: number;
  submodule: string;
  selected: boolean;
}
