import { Subscription, interval } from "rxjs";
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import * as moment from "moment";
import { DoCheck, KeyValueDiffers, KeyValueDiffer } from "@angular/core";
import { SharedCommunicationService } from "../../../../services/shared-communication.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import Swal from "sweetalert2";

@Component({
  selector: "app-questions",
  templateUrl: "./questions.component.html",
  styleUrls: ["./questions.component.css"],
})
export class QuestionsComponent implements OnInit {
  @Input() question: any = {};
  @Input() hasTimer: Boolean = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() pickAnswer: EventEmitter<any> = new EventEmitter();

  private subscription: Subscription;

  isOther = false;
  timer: Date;
  enableTimer = false;
  playable = false;

  passed = 0;
  percent = 0;

  color = "primary";

  options: any = {
    idpregunta: "0",
    idrespuesta: "0",
    text: "",
  };

  respuesta = 0;

  differ: any;

  ngDoCheck() {
    const change = this.differ.diff(this);

    if (change) {
      change.forEachChangedItem((item) => {
        if (item.key == "respuesta" && item.currentValue != null) {
          this.pickAnswer.emit(2);
        }
      });
    }
  }

  constructor(
    private differs: KeyValueDiffers,
    private shared: SharedCommunicationService
  ) {
    this.differ = this.differs.find({}).create();

    this.shared.evaluationTimEnabler.subscribe((e: boolean) => {
      // habilita si la bandera es true y si tiene timer
      if (e == true && this.question.addTimer == true) {
        // inicializa temporizador
        this.set_timer(this.question.timeLimit);

        // muestra en pantalla
        this.enableTimer = true;
      } else {
        this.enableTimer = false;
      }
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  initialize() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question.currentValue != null) {
      console.info("cambios questions => ", changes.question.currentValue);
    }
  }

  set_timer(ev) {
    if (ev == 0) {
      return;
    }

    this.timer = new Date(moment().add(ev, "seconds").toDate());

    this.subscription = interval(1000).subscribe((x) => {
      if (x >= ev) {
        return;
      }

      this.passed = Number(Number(((x + 1) / ev) * 100).toFixed(0));

      if (this.passed < 30) {
        this.color = "ok-bar";
      }

      if (this.passed >= 30 && this.passed < 59) {
        this.color = "warn-bar";
      }

      if (this.passed >= 60) {
        this.color = "danger-bar";
      }
    });
  }

  validateOther(ev) {
    const selected = this.question.answers.find((x) => x.id == ev.value);

    this.isOther = selected.name.includes("Otro") ? true : false;
  }

  saveAction() {
    // ocultar barra del timer
    this.enableTimer = false;

    // bloquear candado
    this.playable = false;

    // Pasar variable
    this.options.idrespuesta = this.respuesta;

    // Reset
    this.passed = 0;
    this.color = "ok-bar";

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // Validate date
    this.options.idrespuesta =
      this.question.type == 2
        ? moment(this.options.idrespuesta).format("YYYY-MM-DD").toString()
        : this.options.idrespuesta;

    // Valida si trae 'otro'
    this.options.idrespuesta =
      this.isOther == true
        ? this.options.idrespuesta + "|" + this.options.text
        : this.options.idrespuesta;

    // Assigne question ID
    this.options.idpregunta = this.question.id;

    this.options.type = this.question.type;

    this.options.MultipleAnwers = [];

    const filterOptions = [6, 7];

    if (filterOptions.includes(this.options.type)) {
      this.question.answers.forEach((b: any, index) => {
        this.options.MultipleAnwers.push({
          IdAnswer: b.id,
          Answer: b.name,
          Selected: b.selected ? b.selected : false,
          Order: index,
        });
      });
    }

    if (
      this.question.max !== 0 &&
      this.question.type === 6 &&
      this.options.MultipleAnwers.filter((a) => a.Selected).length !==
        this.question.max
    ) {
      Swal.fire({
        text: `Debes seleccionar ${this.question.max} opciones`,
        icon: "warning",
        showConfirmButton: true,
      });
      return;
    }

    // Send to parent
    this.save.emit({
      idpregunta: this.options.idpregunta,
      idrespuesta: filterOptions.includes(this.options.type)
        ? this.options.MultipleAnwers
        : this.options.idrespuesta,
    });

    // Reset variables
    this.options = { idpregunta: null, idrespuesta: null, text: null };
    this.question = {};
    this.isOther = false;
    this.respuesta = null;
  }

  saveActionButton(row) {
    this.respuesta = row.id;
    this.saveAction();
  }

  otherOption(row) {
    var nameResp = row.name.toUpperCase();
    var contieneOtro = nameResp.includes("OTRO");
    this.isOther = contieneOtro;
    if (contieneOtro) {
      this.validateKindOfQuestion(5);
      return;
    }
    this.saveActionButton(row);
  }

  execute() {
    if (this.question.addTimer === true && this.question.timeLimit > 0) {
      this.enableTimer = false;
      this.saveAction();
    }
  }

  validateKindOfQuestion(cc) {
    if (this.isOther != true) {
      return [2, 3, 4, 8, 9, 10, 11, 12, 13].find((x) => x === cc);
    } else {
      return [2, 3, 4, 5, 8, 9, 10, 11, 12, 13].find((x) => x === cc);
    }
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    var intKey = parseInt(event.key);
    const step = parseInt(event.key) - 1;

    if (event.key == "Enter") {
      this.saveActionButton(this.question.answers[step]);
    }

    // if (step >= 0 && step < this.question.answers.length) {
    //   this.saveActionButton(this.question.answers[step]);
    // }
  }

  /*
    Busca confirmar que el elemento de la lista es el ultimo para activar el timmer
    debe existir la bandera y debe estar disponible la bandera local playable,
    #warning, repinta el array en cada movimiento, hay que bloquear localmente
  */

  unlockItem() {
    const test = this.question.answers.filter(
      (x: any) => x.selected == true || x.checked == true
    );

    //console.info('total checked items: ' + test.length);

    // if(test.length > 3) {
    //   Swal.fire('Advertencia', 'Solo se permite seleccionar un maximo de 3 opciones', 'warning');
    //   return this.playable = false;
    // }

    this.playable = test.length >= 1;
  }

  getLast(a) {
    if (a.lastItem && a.lastItem == true && this.playable == false) {
      if (this.question.addTimer == true) {
        // bloquea candado
        this.playable = true;

        // inicializa temporizador
        this.set_timer(this.question.timeLimit);

        // muestra en pantalla
        this.enableTimer = true;
      } else {
        this.enableTimer = false;

        this.playable = false;
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.question.answers,
      event.previousIndex,
      event.currentIndex
    );
  }

  dropLast(row) {
    if (this.playable == false) {
      this.playable = row.lastItem == true;
    }
  }
}
