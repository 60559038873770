import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators
} from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

import { ConnectorHiringroomService } from "../../../services/conector-hiringroom.service";
import { ConectorModuleService } from "../../../services/conector-module.service";
import { IFrameComponent } from "../modals/iframe/iframe.component";
import { environment } from "./../../../../environments/environment";
import { AuthenticationService } from "./../../../services/authentication.service";
import { ConectorJsonService } from "./../../../services/conector-json.service";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.css"],
})
export class ConfigurationComponent implements OnInit {
  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' }
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' }
  ]

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ],
    },
  }
  configForm: FormGroup;
  hiringForm: FormGroup;
  configuration = {
    id: 0,
    logo: 0,
    company: null,
    reporting: null,
    timezone: null,
    token: null,
    enableHiring: false,
    enableWelcome: false,
    enableProcess: false,
    enableTyCos: false,
    enableGender: false,
    enableDateOfBirth: false,
  };
  industries = [];
  sectors = [];
  akey = environment.tiny;
  publicFolder = environment.blobCompany;
  linkTo = null;
  redireccionamiento: any;
  visible = false;
  disableHiringFields = false;
  optionsFrm: FormGroup;
  activeModule = localStorage.getItem("module");
  sectorOrig = "0";

  allowtycos = false;
  @ViewChild(IFrameComponent, { static: true }) ic: IFrameComponent;
  company_options: any = [];
  permissionModifyPrivacyPolicy: boolean = true;
  permissionModifyWelcomeText: boolean = true;
  permissionModifyFinalText: boolean = true;

  constructor(
    public sanitizer: DomSanitizer,
    private conector: ConectorJsonService,
    private conectorModuleService: ConectorModuleService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private auth: AuthenticationService,
    private hiring: ConnectorHiringroomService,
    private ac: ActivatedRoute,
    private router: Router
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);

    this.configForm = this.fb.group({
      company: [null, Validators.required],
      reporting: [null, Validators.required],
      industry: [0, Validators.required],
      sector: [0, Validators.required],
      logo: [null, Validators.required],
      timezone: [null],
      lang: [null, Validators.required],
      enableWelcome: [false],
      enableProcess: [false],
      enableTyCos: [false],
      enableGender: [false],
      enableDateOfBirth: [false],
      tycos_es: [null, [Validators.required, this.notEmptyStringValidator]],
      email_config: [null],
      welcome_es: [null],
      process_es: [null],
      token: [null],
      hiringroom: [null],
    });

    this.configForm.get('welcome_es').clearValidators();
    this.configForm.get('welcome_es').updateValueAndValidity();
    this.configForm.get('process_es').clearValidators();
    this.configForm.get('process_es').updateValueAndValidity();
    this.configForm.get('email_config').clearValidators();
    this.configForm.get('email_config').updateValueAndValidity();

    if (this.activeModule === 'Integriline') {
      this.configForm.get('welcome_es').setValidators([Validators.required, this.notEmptyStringValidator]);
      this.configForm.get('welcome_es').updateValueAndValidity();
      this.configForm.get('process_es').setValidators([Validators.required, this.notEmptyStringValidator]);
      this.configForm.get('process_es').updateValueAndValidity();
    } if (this.activeModule === 'Honestidad') {
      this.configForm.get('email_config').setValidators([Validators.required, this.notEmptyStringValidator]);
      this.configForm.get('email_config').updateValueAndValidity();
    }


    this.hiringForm = this.fb.group({
      account: [null, Validators.required],
    });

    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));

    this.optionsFrm = this.fb.group({
      option: [null, Validators.required],
      contents: [null, Validators.required],
    });
  }

  
  async ngOnInit() {
    this.ac.queryParams.subscribe(async (params) => {
      console.info(100, params);

      if (params["authCode"] != null) {
        // tu codigo
        await this.conector
          .send_options({
            option: "hiring-token",
            contents: params["authCode"],
          })
          .toPromise();

        //Tu variable
        this.hiringForm
          .get("account")
          .setValue(localStorage.getItem("hiringroom_auth"));
        this.configForm
          .get("hiringroom")
          .setValue(this.hiringForm.value.account);
      }
    });

    this.conector.cfg_get().subscribe((d: any) => {
      const { ok, msg, data }: any = d;
      this.configuration = data;
      this.disableHiringFields = msg == "True";
      this.configuration.enableTyCos = data.enableTyCos;

      this.sectorOrig = data.sector.toString();

      Object.keys(this.configuration).forEach((key) => {
        if (this.configForm.get(key)) {
          this.configForm.get(key).setValue(this.configuration[key]);
        }
      });

      if (this.configuration.token != null) {
        var protocol = window.location.port ? `:${window.location.port}` : "";

        this.linkTo = `${window.location.protocol}//${window.location.hostname}${protocol}/link-to/${this.configuration.token}`;
      }

      this.hiringForm.get("account").setValue(data.hiringroom);
      this.configForm.get("hiringroom").setValue(data.hiringroom);

      this.load_Industry_BySector(this.sectorOrig);
    });

    this.conector.sectors_get("all").subscribe((r: any) => {
      const { ok, data, msg } = r;

      data.forEach(async (item) => {
        this.translate.get(item.name).subscribe((k) => {
          item.name = k;
        });
      });

      data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.sectors = data;
    });

    this.reloadOptions();

    // this.conector.industries_get("all").subscribe((r: any) => {
    //   const { ok, data, msg } = r;

    //   var idSector =  this.company_options.companyId;
    //   var filterData = data.filter((x) => x.sector === idSector);

    //   filterData.forEach(async (item) => {
    //     this.translate.get(item.name).subscribe((k) => {
    //       item.name = k;
    //     });
    //   });

    //   filterData.sort((a, b) => {
    //     if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
    //     if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
    //     return 0;
    //   });

    //   this.industries = filterData;

    //  });
  }

  load_Industry_BySector(event: any) {
    this.conector.industries_get("all").subscribe((r: any) => {
      const { ok, data, msg } = r;

      var idSector = event;
      this.configForm.value.sector = idSector;
      var filterData = data.filter((x) => x.sector === idSector);

      filterData.forEach(async (item) => {
        this.translate.get(item.name).subscribe((k) => {
          item.name = k;
        });
      });

      filterData.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.industries = filterData;
    });
  }

  authorizeHiringroom(formdata) {
    this.conector
      .post_hiringField({
        id: this.configuration.id,
        hiringroom: formdata.account,
        enableHiring: true,
      })
      .subscribe((e: any) => {
        console.info("hiringroom", e);

        let ss = `${environment.hiringroom_server}/oauth2/authorization?response_type=authcode&client_id=${environment.hiringroom_client_id}&account=${formdata.account}&state=state`;

        localStorage.setItem("hiringroom_auth", formdata.account);

        window.location.href = ss;
      });
  }

  unlinkAccountHiringroom() {
    Swal.fire({
      title: "¿Seguro desea desvincular la cuenta con Hiring?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector.unlinkHiring().subscribe(async (e: any) => {
          //this.configForm.get('email_config').setValue(e.data);
          this.disableHiringFields = false;
          Swal.fire(e.msg, "", "success");
        });
      } else {
        Swal.fire("No se desvinculó la cuenta", "", "info");
      }
    });
  }

  updateTimezone($event) {
    console.info("tzdata&", $event);
    this.configForm.get("timezone").setValue($event.name);

    var formprue = this.configForm;
    var prueb = this.configForm.value.industry;
  }

  updateConfig(formdata) {
    formdata.id = this.configuration.id;
    formdata.enableWelcome = this.configForm.get("enableWelcome").value;
    formdata.isEnableProcessText = this.configForm.get("enableProcess").value;
    formdata.isEnableTyCosText = this.configForm.get("enableTyCos").value;
    formdata.enableGender = this.configForm.get("enableGender").value;
    formdata.enableDateOfBirth = this.configForm.get("enableDateOfBirth").value;

    this.conector.company_put(formdata).subscribe(
      async (r: any) => {
        const msg = await this.translate.get("success").toPromise();
        Swal.fire(msg, "", "success");
      },
      (e: any) => {
        console.info("Er&", e);
      }
    );
  }

  onSelect($ev) {
    const formData = new FormData();

    formData.append("file", $ev.addedFiles[0]);

    this.conector.company_logo(formData).subscribe((r: any) => {
      this.configuration.logo = r.msg;

      this.conector.company_put(this.configuration).subscribe(
        async (x: any) => {
          const msg = await this.translate.get("success").toPromise();

          Swal.fire(msg, "", "success");
        },
        (e: any) => {
          console.info("Er&", e);
        }
      );
    });
  }

  reloadOptions() {
    this.conector.get_options().subscribe((e: any) => {
      this.company_options = e.data;
    });
  }

  saveOptions(data) {
    this.conector.send_options(data).subscribe((e: any) => {
      this.optionsFrm.reset();
      this.reloadOptions();
    });
  }

  deleteOption(row) {
    console.info("row", row);

    this.conector.delete_options(row.id).subscribe((e: any) => {
      this.reloadOptions();
    });
  }

  resetEmail() {
    Swal.fire({
      title: "¿Seguro desea reestablecer el correo electrónico?",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.conector.reset_email().subscribe(async (e: any) => {
          this.configForm.get("email_config").setValue(e.data);
          Swal.fire(e.msg, "", "success");
        });
      } else {
        Swal.fire("Correo electrónico no reestablecido", "", "info");
      }
    });
  }

  public enableWelcomeMessage(): void {
    const tittle: string = "Mensaje de bienvenida";
    const formControl: AbstractControl = this.configForm.get("enableWelcome");
    const valueCheckboxWelcome: boolean | null = formControl.value;
    const messageWelcome: string | null = this.configForm.get("welcome_es").value;
    this.validateAndSave(
      valueCheckboxWelcome,
      messageWelcome,
      formControl,
      tittle
    );
  }

  public enableProcessMessage(): void {
    const tittle: string = "Mensaje Final";
    const formControl: AbstractControl = this.configForm.get("enableProcess");
    const valueCheckboxProcess: boolean | null = formControl.value;
    const messageProcess: string | null = this.configForm.get("process_es").value;
    this.validateAndSave(
      valueCheckboxProcess,
      messageProcess,
      formControl,
      tittle
    );
  }

  public enableTyCosMessage(): void {
    const tittle: string = "Términos y Condiciones";
    const formControl: AbstractControl = this.configForm.get("enableTyCos");
    const valueCheckboxTyCos: boolean | null = formControl.value;
    const messageTyCos: string | null = this.configForm.get("tycos_es").value;
    this.validateAndSave(valueCheckboxTyCos, messageTyCos, formControl, tittle);
  }

  public isEmptyEditorWelcomeMessage(event: string): void {
    const formControl: AbstractControl = this.configForm.get("enableWelcome");
    this.isEmptyEditorFields(event, formControl);
  }

  public isEmptyEditorProcessMessage(event: string): void {
    const formControl: AbstractControl = this.configForm.get("enableProcess");
    this.isEmptyEditorFields(event, formControl);
  }

  public isEmptyEditorTyCosMessage(event: string): void {
    const formControl: AbstractControl = this.configForm.get("enableTyCos");
    this.isEmptyEditorFields(event, formControl);
  }

  private isEmptyEditorFields(
    value: string,
    formControl: AbstractControl
  ): void {
    let messageWithoutHtmlTag: string = value
      .replace(/<[^>]*>|&nbsp;/g, "")
      .trim();
    if (!(messageWithoutHtmlTag === null || messageWithoutHtmlTag === ""))
      return;
    formControl.setValue(false);
  }

  private validateAndSave(
    value: boolean | null,
    message: string | null,
    formControl: AbstractControl,
    tittle: string = ""
  ): void {
    if (!value) {
      return;
    }
    let messageWithoutHtmlTag: string = message
      .replace(/<[^>]*>|&nbsp;/g, "")
      .trim();
    if (messageWithoutHtmlTag === null || messageWithoutHtmlTag === "") {
      Swal.fire({
        title: tittle,
        text: "No puede estar vacío",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      formControl.setValue(false);
    }
    return;
  }
  notEmptyStringValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value.replace(/<[^>]*>|&nbsp;/g, "").trim() === '') {
      return { emptyString: true };
    }
    return null;
  }
  getIdModuleLibrary() {
    const currentRoute = this.router.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }
  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "chooseSector") {
              if (!item.havePermission) this.configForm.get("sector").disable();
            } else if (item.code === "chooseIndustry") {
              if (!item.havePermission)
                this.configForm.get("industry").disable();
            } else if (item.code === "changeLanguage") {
              if (!item.havePermission) this.configForm.get("lang").disable();
            } else if (item.code === "changeTimezone") {
              if (!item.havePermission)
                this.configForm.get("timezone").disable();
            } else if (item.code === "activeOrDesactivePrivacyPolicy") {
              if (!item.havePermission)
                this.configForm.get("enableTyCos").disable();
            } else if (item.code === "modifyPrivacyPolicy") {
              this.permissionModifyPrivacyPolicy = item.havePermission;
            } else if (item.code === "activeOrDesactiveWelcomeText") {
              if (!item.havePermission)
                this.configForm.get("enableWelcome").disable();
            } else if (item.code === "modifyWelcomeText") {
              this.permissionModifyWelcomeText = item.havePermission;
            } else if (item.code === "activeOrDesactiveFinalMessage") {
              if (!item.havePermission)
                this.configForm.get("enableProcess").disable();
            } else if (item.code === "modifyFinalMessage") {
              this.permissionModifyFinalText = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
}
