import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import Swal from 'sweetalert2';
import { ConnectorEthicsSurveyService } from '../../../../services/ethics-survey/conector-ethics-survey.service';
import { ConfigProyectComponent } from '../../config-proyect/config-proyect.component';

@Component({
  selector: 'app-delete-config',
  templateUrl: './delete-config.component.html',
  styleUrls: ['./delete-config.component.css']
})
export class DeleteConfigComponent implements OnInit {
id : number = 0 ;
  constructor(
    public childDialogRef: MatDialogRef<DeleteConfigComponent>,
    public parentDialogRef : MatDialogRef<ConfigProyectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {idSurvey: number},
    @Inject(MAT_DIALOG_DATA) public dataf:  MatDialogRef<ConfigProyectComponent>,

    private conector: ConnectorEthicsSurveyService, ) { 
    this.id = data.idSurvey;
    this.parentDialogRef =  dataf;

  }
  

  ngOnInit() {
  }
  submit() {
    this.conector.ethicsSurveyDelete(this.id).subscribe( (response: any) => {

      const {ok, data, msg} = response;
      debugger;
      if (ok === false) {
        return Swal.fire('Error', msg, 'error')

      } else {
        const {data, msg} = response;
        Swal.fire('Buen trabajo !', 'Configuracion eliminada', 'success');
        this.childDialogRef.close();
        this.parentDialogRef.close();
        data.route.navigate(["manager/Survey"]);
      }
    });

  }

}
