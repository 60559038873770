import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConnectorEthicsSurveyService } from '../../../services/ethics-survey/conector-ethics-survey.service';
import { ExportDataSurveyComponent } from '../../manager/modals/export-data-survey/export-data-survey.component';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-reports-ethics-survey',
	templateUrl: './reports-ethics-survey.component.html',
	styleUrls: ['./reports-ethics-survey.component.css'],
})
export class ReportsEthicsSurveyComponent implements OnInit {
	message: String = 'Seleccione un tipo de proyecto para obtener sus proyectos';
	projects: any[] = [];
	projectsFilters: any[] = [];
	viewSelectProjects: boolean = false;
	dataSourceGroups: MatTableDataSource<Groups>;
	tableMessage = 'Por favor, selecciona un proyecto para obtener su información.';
	displayedColumns: string[] = ['select', 'nameGroup'];
	dataSourceSubgroups: MatTableDataSource<Subgroups>;
	typeProjectSelected: number = 0;
	subtypeProjectSelected: number = 0;
	disabledButtonsActions: boolean = false;
	idProjectSelected: number = 0;

	/* 
   {
        "userId": 8398,
        "id": 1226189,
        "name": "Ara",
        "email": "masv.devsoft@amitai.com",
        "endDate": "2024-10-09T00:00:00",
        "selected": false,
        "group": "Cumplimiento",
        "subgroup": null
    }
  */
	displayedColumnsNom35: string[] = ['selected', 'name', 'email', 'group', 'subgroup', 'endDate', 'report'];

	dataSourceNom35: MatTableDataSource<any>;

	constructor(private conector: ConnectorEthicsSurveyService, public dialog: MatDialog) {
		this.getAllSurveys();
		this.dataSourceGroups = new MatTableDataSource([]);
		this.dataSourceSubgroups = new MatTableDataSource([]);
		this.dataSourceNom35 = new MatTableDataSource([]);
	}
	ngOnInit(): void {}
	getAllSurveys() {
		this.conector.getAllSurveys().subscribe((response: any) => {
			const { ok, data, msg } = response;
			if (ok) {
				this.projects = data;
			} else {
				console.log('Error get all surveys');
			}
		});
	}
	changeTypeProyect(typeProject: number) {
		this.typeProjectSelected = typeProject;
		this.projectsFilters = this.projects.filter((item) => item.type == typeProject);

		if (this.projectsFilters.length == 0) {
			this.message = 'No se encontraron registros de este tipo de proyecto';
			this.viewSelectProjects = false;
		} else {
			this.viewSelectProjects = true;
		}
	}
	getGroupByProject(idProject: number, subtypeProject: number) {
		this.subtypeProjectSelected = subtypeProject;
		this.tableMessage = 'Obtiendo la información...';
		this.conector.getGroupsAndSubgroupsByProject(idProject).subscribe((response: any) => {
			const { ok, data, msg } = response;
			if (ok) {
				this.dataSourceGroups.data = data;
				this.dataSourceSubgroups.data = [].concat(
					...this.dataSourceGroups.data.map((group) => group.subgroups)
				);
				this.idProjectSelected = idProject;
				if (this.dataSourceGroups.data.length == 0) {
					this.tableMessage = 'El proyecto seleccionado no emitio ningún resultado.';
				}
			} else {
				console.log('Error get groups and subgroups by project');
			}
		});

		this.getEvaluationNom35(idProject);
	}

	getEvaluationNom35(idProject: number) {
		this.conector.getEvaluationsByProject(idProject).subscribe((response: any) => {
			this.dataSourceNom35 = new MatTableDataSource(response);
		});
	}

	downloadReportNom35(row: any): void {
		console.log('Descargando reporte de Nom35', row);
		this.conector.downloadReportNom35(row.id).subscribe(
			(response: Blob) => {
				// Crear un enlace (a) temporal
				const blob = new Blob([response], { type: 'application/pdf' });
				const url = window.URL.createObjectURL(blob);

				const a = document.createElement('a');
				let fileName = `NOM35_${row.nickname}${new Date().toISOString()}.pdf`;
				fileName = fileName.replace(/:/g, '-');
				a.download = fileName;
				a.href = url;

				a.click();

				window.URL.revokeObjectURL(url);
			},
			(error) => {
				console.error('Error descargando el reporte', error);
			}
		);
	}

	checkGroup(row) {
		const { groupId, groupSelected } = row;
		this.dataSourceSubgroups.data.forEach((subgroup) => {
			if (subgroup.groupId === groupId) {
				subgroup.subgroupSelected = groupSelected;
			}
		});
		this.disabledButtonsActions = this.dataSourceGroups.data.filter((item) => item.groupSelected).length == 0;
		this.dataSourceSubgroups.data = [...this.dataSourceSubgroups.data];
	}
	checkSubgroup(row) {
		const { groupId } = row;
		var allSubgroupsSelected =
			this.dataSourceSubgroups.data.filter((item) => item.groupId === groupId && item.subgroupSelected).length >
			0;
		this.dataSourceGroups.data
			.filter((item) => item.groupId === groupId)
			.map((group) => (group.groupSelected = allSubgroupsSelected));
		this.disabledButtonsActions = this.dataSourceGroups.data.filter((item) => item.groupSelected).length == 0;
		this.dataSourceGroups.data = [...this.dataSourceGroups.data];
	}
	exportData() {
		const group = this.dataSourceGroups.data.filter((item) => item.groupSelected);
		const idGroup = group.length > 1 ? 0 : group[0].groupId;

		this.conector.exportDataNom035(this.idProjectSelected, idGroup).subscribe(
			(response) => {
				const blob = new Blob([response], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const fileName = `export_data_${this.idProjectSelected}.xlsx`;

				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = fileName;
				link.click();
			},
			(error) => {
				return Swal.fire('Error', 'No se pudo exportar los datos ', 'error');
			}
		);
	}
	downloadReportEngagement() {
		const group = this.dataSourceGroups.data.filter((item) => item.groupSelected);
		const idGroup = group.length > 1 ? 0 : group[0].groupId;
		this.conector.PdfGetReportEngagement(this.idProjectSelected, idGroup.toString()).subscribe((response: any) => {
			const { ok, data, msg } = response;

			if (ok) {
				const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
				const downloadLink = document.createElement('a');
				const fileName = 'Type1ReportEngagement.pdf';
				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			} else {
				return Swal.fire('Error', 'No se pudo descargar el reporte ', 'error');
			}
		});
	}
	downloadReportEngagementAndChangesAditional() {
		const group = this.dataSourceGroups.data.filter((item) => item.groupSelected);
		const idGroup = group.length > 1 ? 0 : group[0].groupId;
		this.conector.PdfGetReportClima(this.idProjectSelected, idGroup.toString()).subscribe((response: any) => {
			const { ok, data, msg } = response;
			if (ok) {
				const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
				const downloadLink = document.createElement('a');
				const fileName = 'Type2PdfGetReportClima.pdf';

				downloadLink.href = linkSource;
				downloadLink.download = fileName;
				downloadLink.click();
			} else {
				return Swal.fire('Error', 'No se udo generar el informe ' + msg, 'error');
			}
		});
	}
	downloadReportNom035(/*debe de recibir una lista de grupos o subgrupos, el id del proyecto */) {
		console.log('Lógica para descargar el reporte de Nom035');
		// this.conector
		//   .PdfGetReportNom035(this.idSurvey, id)
		//   .subscribe((response: any) => {
		//     this.ShowSpinnerStep2 = true;

		//     const { ok, data, msg } = response;

		//     if (ok == true) {
		//       const linkSource =
		//         "data:application/pdf;base64," + response.data.pdfBase64;
		//       const downloadLink = document.createElement("a");
		//       const fileName = "PdfGetReportNom035.pdf";

		//       downloadLink.href = linkSource;
		//       downloadLink.download = fileName;
		//       downloadLink.click();
		//       this.ShowSpinnerStep2 = false;
		//     } else {
		//       return Swal.fire(
		//         "Error",
		//         "No se udo generar el informe " + msg,
		//         "error"
		//       );
		//     }
		//   });
	}
}

export interface Groups {
	groupId: number;
	groupName: string;
	groupSelected: boolean;
	subgroups: Subgroups[];
}

export interface Subgroups {
	groupId: number;
	subgroupId: number;
	subgroupName: string;
	subgroupSelected: boolean;
}
