import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { v4 as uuid } from 'uuid';
import { ConnectorJobProfileService } from '../../../services/job-profile.service';
import { JobPosition } from '../../../types/db-job-positions.type';
import { JobprofileSurveysComponent } from '../modals/jobprofile-surveys/jobprofile-surveys.component';
import { LinkJobprofileComponent } from '../modals/link-jobprofile/link-jobprofile.component';
import { catchError, finalize } from 'rxjs/operators';

@Component({
	selector: 'app-jobprofile',
	templateUrl: './jobprofile.component.html',
	styleUrls: ['./jobprofile.component.css'],
})
export class JobProfileComponent implements OnInit {
	displayedColumns: string[] = [
		'updateJobV8',
		'puesto',
		'nivel',
		'iconIntegrity',
		'totalEvaluationsHonestySelection',
		'iconProfile',
		'totalEvaluationsProfile',
		'functions',
	];

	hiringEnabled = null;
	totalJobs = 0;
	pageSize = 10;
	currentPage = 0;
	sortDescending = true;
	enabledGrid = true;
	isLoading = true;
	enabledDataGeneral = false;
	enabledAdjustmentNorm = false;
	isEditingValue = false;

	search = '';
	value = 'Techiediaries';

	jobSubmodules = [];

	jobPositions: JobPosition[] = [];
	pageSizeOptions: number[] = [10, 25, 50];
	currentItem = {};
	jobSelected = { id: 0 };

	dataSource = new MatTableDataSource<JobPosition>(null);

	@ViewChild(JobprofileSurveysComponent, { static: true })
	jpc: JobprofileSurveysComponent;
	@ViewChild(MatPaginator, { static: null }) paginator!: MatPaginator;

	constructor(private conector: ConnectorJobProfileService, private route: Router, public dialog: MatDialog) { }

	ngOnInit() {
		this.loadJobs();
		this.hiringEnabled = localStorage.getItem('enableHiring') == 'true' ? true : false;
	}

	loadJobs() {
		this.conector
			.getJobs(this.currentPage, this.pageSize, this.sortDescending, this.search)
			.pipe(
				catchError(() => []),
				finalize(() => (this.isLoading = false))
			)
			.subscribe(({ data }) => {
				this.dataSource = new MatTableDataSource<JobPosition>(data.jobsPositions);
				this.totalJobs = data.totalJobsPositions;
			});
	}

	onPageChange(event: PageEvent) {
		this.pageSize = event.pageSize;
		this.currentPage = event.pageIndex;
		this.loadJobs();
	}

	searchProfiles() {
		this.loadJobs();
	}

	async AddJobProfile(row: any, isEditing: boolean) {
		this.isEditingValue = isEditing;
		if (row.updateJobV8) {
			Swal.fire({
				title: 'Advertencia',
				icon: 'warning',
				text: 'Este puesto fue configurado con una versión anterior de Perfiles, se sugiere actualizarlo o crear un puesto nuevo.',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				reverseButtons: true,
			}).then((d: any) => {
				if (d.value) {
					row.token = row.token == null ? uuid() : row.token;
					row.analisisRiesgo = row.analisisRiesgo == null ? false : row.analisisRiesgo;

					if (row.id >= 1 && row.hiringId != null && row.hiringResponse != null) {
						const temporary = JSON.parse(row.hiringResponse);
						row.stage = temporary.stage;
					}
					this.currentItem = row;
					// mostrar formulario de apertura
					this.enabledGrid = false;
					this.enabledDataGeneral = true;
					this.enabledAdjustmentNorm = false;
				}
			});
		} else {
			row.token = row.token == null ? uuid() : row.token;
			row.analisisRiesgo = row.analisisRiesgo == null ? false : row.analisisRiesgo;

			if (row.id >= 1 && row.hiringId != null && row.hiringResponse != null) {
				const temporary = JSON.parse(row.hiringResponse);
				row.stage = temporary.stage;
			}

			this.currentItem = row;
			this.enabledGrid = false;
			this.enabledDataGeneral = true;
			this.enabledAdjustmentNorm = false;
		}
	}

	AdjustmentOfNorm(row) {
		if (row.id >= 1 && row.hiringId != null && row.hiringResponse != null) {
			const temporary = JSON.parse(row.hiringResponse);
			row.stage = temporary.stage;
		}

		this.currentItem = row;

		this.enabledDataGeneral = false;
		this.enabledGrid = false;
		this.enabledAdjustmentNorm = true;
	}

	EraseJobProfile(row) {
		Swal.fire({
			title: 'Advertencia',
			icon: 'warning',
			text: 'Esta acción es irreversible',
			showCancelButton: true,
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
		}).then((d: any) => {
			if (d.value === true) {
				this.conector.config_batteryDelete(row.id).subscribe((d: any) => {
					const { ok, msg, data } = d;
					if (ok === true) {
						this.conector.jobprofile_delete(row.id).subscribe((x: any) => {
							const { ok, msg } = x;
							if (ok === true) {
								Swal.fire('Exito', 'Eliminación realizada.', 'success');
								this.loadJobs();
							} else {
								Swal.fire('Error', msg, 'error');
							}
						});
					} else {
						Swal.fire('Error', msg, 'error');
					}
				});
			}
		});
	}

	showDirectCode(e): void {
		var protocol = window.location.port ? `:${window.location.port}` : '';

		if (!e.token) {
			Swal.fire('Error', 'Aun no cuentas con un token, generarlo para obtener un link', 'error');
			return;
		}

		const linkTo = `${window.location.protocol}//${window.location.hostname}${protocol}/to-do/${e.token}`;
		const dialogRef = this.dialog.open(LinkJobprofileComponent, {
			data: { link: linkTo },
		});
		dialogRef.afterClosed().subscribe();
	}

	configJobSurveys(row) {
		this.jobSelected = row;
		this.conector
			.get_job_surveys({
				modulo: localStorage.getItem('module'),
				puesto: row.id,
			})
			.subscribe((e: any) => {
				this.jobSubmodules = e.data;
				this.jpc.openModal = true;
			});
	}

	enabledGridView() {
		this.searchProfiles();
		this.enabledGrid = true;
		this.enabledDataGeneral = false;
		this.enabledAdjustmentNorm = false;
	}

	openOwnQuestions() {
		this.route.navigateByUrl('/manager/OwnQuestions');
	}

	openOwnCompetencies() {
		this.route.navigateByUrl('/manager/OwnCompetencies');
	}
}