import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatPaginator } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ConectorModuleService } from "../../../services/conector-module.service";
import { ConnectorEthicsSurveyService } from "../../../services/ethics-survey/conector-ethics-survey.service";
import { CreateConfigComponent } from "../modal/create-config/create-config.component";
import { DeleteConfigComponent } from "../modal/delete-config/delete-config.component";
import { Pagination } from "./../../../models/pagination";
import { AuthenticationService } from "./../../../services/authentication.service";
import { Router } from "@angular/router";
import { LinkProyectComponent } from '../../manager/modals/link-proyect/link-proyect.component';

@Component({
  selector: "app-config-proyect",
  templateUrl: "./config-proyect.component.html",
  styleUrls: ["./config-proyect.component.css"],
})
export class ConfigProyectComponent implements OnInit {
  pagination = new Pagination();
  showActive = 1;
  selectedIndex = 0;
  activeRow = null;
  proyecto = [];
  displayedColumns: string[] = [
    "nameType",
    "nameProyect",
    "nameCompany",
    "startDate",
    "endDate",
    "total",
    "nameStatus",
    "actions",
  ];
  @ViewChild(MatPaginator, { static: true }) paginationBar: MatPaginator;

  permissionCreateProject: boolean = true;
  permissionUpdateProject: boolean = true;
  permissionDeleteProject: boolean = true;
  permissionNotifyEvaluateds: boolean = true;
  permissionExportData: boolean = true;
  constructor(
    public datepipe: DatePipe,
    private conector: ConnectorEthicsSurveyService,
    private conectorModuleService: ConectorModuleService,
    public dialog: MatDialog,
    private route: Router,
    public translate: TranslateService,
    public auth: AuthenticationService
  ) {
    const idModuleLibrary = this.getIdModuleLibrary();
    this.getFunctionalityPermissions(idModuleLibrary);
    this.auth.setLanguage().then((k) => this.translate.setDefaultLang(k));
    this.pagination.length = 10;
  }

  ngOnInit() {
    this.get_ethics_suvey();
  }

  // ge list proyect
  get_ethics_suvey() {
    this.conector
      .ethicsSurveyGet(
        this.showActive,
        this.pagination.init,
        this.pagination.limit
      )
      .subscribe((response: any) => {
        const { ok, data, msg } = response;

        if (!ok) {
          return Swal.fire("Error", msg, "error");
        } else {
          const { data, msg } = response;
          this.proyecto = data;
          this.pagination.length = msg;
        }
      });
  }

  showDirectCode(e): void {
		var protocol = window.location.port ? `:${window.location.port}` : '';

		if (!e.token) {
			Swal.fire('Error', 'Aun no cuentas con un token, generarlo para obtener un link', 'error');
			return;
		}

		const linkTo = `${window.location.protocol}//${window.location.hostname}${protocol}/to-do/${e.token}`;
    Swal.fire('Link para evaluación', linkTo);
		return;
		// const dialogRef = this.dialog.open(LinkProyectComponent, {
		// 	data: { link: linkTo },
    //   width: "70%",
		// });

    const dialogRef = this.dialog.open(LinkProyectComponent, {
      data: { idSurvey: 0 },
      width: "70%",
    });

		dialogRef.afterClosed().subscribe();
	}

  AddConfig(row) {
    const dialogRef = this.dialog.open(CreateConfigComponent, {
      data: row,
      width: "70%",
    });

    // dialogRef.afterClosed().subscribe(res => {
    //   this.conector.users_get().subscribe( (response: any) => {
    //     const {ok, data, msg} = response;
    //     this.users = data;
    //   });
    // });
  }
  AddDelete(id: number) {
    const dialogRef = this.dialog.open(DeleteConfigComponent, {
      data: { idSurvey: id },
      width: "70%",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.get_ethics_suvey();
    });
  }

  // AddConfig2() {

  //   this.conector.PdfGetReportEngagement("85").subscribe( (response: any) => {

  //     const {ok, data, msg} = response;
  //     debugger;
  //     const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
  //     const downloadLink = document.createElement("a");
  //     const fileName = "sample.pdf";

  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     debugger;

  //   });

  // }
  // AddConfig3() {

  //   this.conector.PdfGetReportNom035("85").subscribe( (response: any) => {

  //     const {ok, data, msg} = response;
  //     debugger;
  //     const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
  //     const downloadLink = document.createElement("a");
  //     const fileName = "sample.pdf";

  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     debugger;

  //   });
  // }
  // AddConfig4() {

  //   this.conector.PdfGetReportClima("85").subscribe( (response: any) => {

  //     const {ok, data, msg} = response;
  //     debugger;
  //     const linkSource = 'data:application/pdf;base64,' + response.data.pdfBase64;
  //     const downloadLink = document.createElement("a");
  //     const fileName = "sample.pdf";

  //     downloadLink.href = linkSource;
  //     downloadLink.download = fileName;
  //     downloadLink.click();
  //     debugger;

  //   });
  // }

  onChange($event) {
    const { checked } = $event;

    this.proyecto = [];
    this.pagination.length = 0;
    this.showActive = checked === true ? 0 : 1;

    this.conector
      .ethicsSurveyGet(
        this.showActive,
        this.pagination.init,
        this.pagination.limit
      )
      .subscribe((response: any) => {
        const { data, msg } = response;
        this.pagination.length = msg;

        this.proyecto = data;

        if (this.paginationBar) {
          this.paginationBar.firstPage();
        }
      });
  }

  changePage($event) {
    this.conector
      .ethicsSurveyGet(this.showActive, $event.pageIndex, $event.pageSize)
      .subscribe((response: any) => {
        const { msg, data } = response;
        this.pagination.length = msg;
        this.proyecto = data;
      });
  }
  getIdModuleLibrary() {
    const currentRoute = this.route.url;
    const routesString = localStorage.getItem("menu-sidebar");
    const routesArray = JSON.parse(routesString);
    const routeMatch = routesArray.find(
      (route) => route.route === currentRoute
    );
    return routeMatch.id;
  }

  getFunctionalityPermissions(idModuleLibrary: number) {
    this.conectorModuleService
      .getFunctionalityPermissionByModuleAndUser(idModuleLibrary)
      .subscribe((response: any) => {
        const { ok, data } = response;
        if (ok) {
          const result = JSON.parse(data);
          result.forEach((item) => {
            if (item.code === "createProject") {
              this.permissionCreateProject = item.havePermission;
            } else if (item.code === "updateProject") {
              this.permissionUpdateProject = item.havePermission;
            } else if (item.code === "deleteProject") {
              this.permissionDeleteProject = item.havePermission;
            } else if (item.code === "notifyEvaluateds") {
              this.permissionNotifyEvaluateds = item.havePermission;
            } else if (item.code === "exportDataProject") {
              this.permissionExportData = item.havePermission;
            }
          });
        } else {
          console.log("Error get functionality permissions");
        }
      });
  }
}
